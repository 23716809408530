@import 'avizia_styles';

/*videocomponents*/

.chip-jumbo-flat {
    min-width: 3rem;
    height: 3rem;
    font-size: $fz-h5;
    letter-spacing: 0.04em;

    .chip-content.layout-row {
        .icon,
        .material-icons {
            margin-right: 1rem;
        }
    }
}

.icon-link-jumbo {
    text-decoration: none !important;

    &:hover {
        .material-icons {
            text-decoration: none;
        }
    }

    .material-icons {
        line-height: 64px;
        font-size: 32px;
        width: 60px;
        height: 60px;
    }
}

.btn-jumbo {
    width: 156px;
    height: 156px;
    font-size: 1rem;

    h3 {
        font-size: $fz-h3;
        font-weight: $fw-normal;
    }
}

.slider-jumbo {
    .noUi-horizontal .noUi-handle {
        width: 42px;
        height: 42px;
        left: -21px;
        top: -17px;
    }
}

.companion {
    top: 10px;
}

.video-topnav {
    .topnav-left {
        span {
            font-size: 1rem;
            line-height: 1;
        }
    }

    .topnav-right {
        h4 {
            font-size: $fz-h4;
            line-height: 2rem;
            margin-right: 4rem;
        }

        .bat-icon {
            font-size: 3rem;
            line-height: 1;
            top: -0.5rem;
            right: 0;
        }
    }
}

.video-bottomnav {
    height: 120px;

    &.menu-open {
        height: 40px;

        .button-wrap {
            margin-top: -46px;
        }
    }

    &.menu-open {
        .menu-button {
            -webkit-transform: translateX(66px);
            -moz-transform: translateX(66px);
            transform: translateX(66px);
        }
    }
}

.menu-main {
    padding: 2rem 0;
    margin-bottom: -280px;

    button,
    .menu-panel {
        margin-left: 0.625rem;
    }

    & > * > *:last-child {
        margin-right: 0.625rem;
    }

    & > *,
    & > * > *,
    & > * > * > * {
        display: inline-block;
        flex: 1 0 auto;
    }

    .menu-panel {
        .panel-top {
            padding-top: 2.813rem;
            padding-bottom: 2.188rem;

            & > * {
                margin-right: 0.625rem;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}

.ui-carousel {
    margin-bottom: 0;

    .notification-carousel {
        .notifications-carousel__item {
            .btn-flat {
                padding-left: 20px;
                padding-right: 20px;

                &.video-screen-unmute {
                    color: $white !important;
                    font-size: 0.875rem !important;
                }
            }
        }
    }
}

aw-notifications-carousel {
    button {
        color: $white !important;
        background: transparent;
        border: none;
        font-size: 16px !important;
        font-weight: 600;
    }
}

.panel-top-slider {
    min-width: 510px;
}

.enable-cam-btn {
    margin-top: 0.75rem;
}

.s-prev,
.s-next {
    margin-top: 34px;
}

.call-buttons {
    width: 350px;
    margin-top: 5rem;

    .button-wrap {
        text-align: center;

        h4 {
            font-size: 1.25rem;
        }
    }
}

.call-log .call-log-column {
    h4 {
        font-size: 23px;
    }

    .row .col {
        font-size: 23px;
        line-height: 32px;
    }
}

.chip-jumbo-flat {
    &.help-chip {
        padding: 0 2rem;

        &.active {
            padding: 0 0.4rem;
        }
    }

    .chip-icon-close .material-icons {
        font-size: 1.5rem;
        line-height: 48px;
    }
}

.m-t12 {
    margin-top: 0.75rem;
}

.menu-main .menu-panel {
    &.device-panel {
        padding-left: 0;
    }
}

.device-img {
    width: 160px;
    height: 160px;
    margin: 0 20px;

    img {
        padding: 10px 0;
    }
}

.panel-textarea-wrap {
    width: 740px;
    padding: 30px;
}

.input-field {
    textarea.bordered-textarea.textarea-jumbo {
        padding: 1.5em 1.2rem 1em 1rem;

        & + label {
            font-size: 24px;
            padding-top: 0.7em;
            left: 1rem;
            top: 1px;
            width: calc(100% - 2.2rem);
            transition-property: padding, font-size;

            &.active {
                padding-top: 0.3em;
                font-size: 20px;
            }
        }
    }
}

.modal.modal-share {
    .modal-footer {
        border-radius: 0 0 8px 8px;
    }

    .preview-item {
        border-radius: 0 0 8px 8px;
    }

    .modal-content {
        h2 {
            font-weight: 400;
            margin: 0 0 1em;
            line-height: 3rem;
            font-size: 2.25rem;
        }

        h3 {
            font-weight: 400;
            margin: 0 0 1em;
            line-height: 2rem;
            font-size: 1.5rem;
        }
    }
}

.share-data {
    font-size: $fz-h4;
}

.help-slider {
    .hslider {
        .hslider-item {
            padding: 0 1rem;
            margin: 0 0.625rem;
            width: 675px;

            h3 {
                font-weight: 400;
                margin-bottom: 0.5rem;
                line-height: 1rem;
                font-size: 1rem;
            }

            .hslide-step {
                font-size: $fz-normal;
            }

            .hslide-top {
                height: 80px;
            }

            .hslide-bottom {
                display: flex;
                flex-direction: column;
            }

            &.long-descr {
                .hslide-top {
                    height: 113px;
                }
            }
        }
    }

    .chip-jumbo-flat.help-chip {
        padding: 0 0.4rem;
        top: 45px;
        left: -32px;
    }
}

.slide-icon {
    font-size: $fz-h1;
}

.help-slider:before {
    top: 150px;
    left: -30px;
    width: 30px;
}

.menu-main .menu-panel {
    &.help-in {
        padding-right: 30px;

        .help-slider {
            .panel-top {
                padding-top: 2.813rem;
                padding-bottom: 0.5625rem;
            }

            &.active {
                padding-left: 42px;
                padding-right: 10px;
            }
        }

        .cam-control-buttons {
            .panel-top {
                padding-top: 2rem;
                padding-bottom: 1.375rem;
            }
        }

        .action-section {
            .panel-top {
                padding-right: 112px;
            }
        }

        .text-pull {
            width: calc(100% - 132px);
        }

        &.help-open {
            .action-section {
                .panel-top {
                    margin-right: 32px;
                }
            }
        }
    }
}

.people-count {
    h1 {
        margin-top: 50px;
        margin-bottom: 1rem;
        font-size: $fz-h1;
    }

    h3 {
        font-size: $fz-h3;
    }
}

.img-panel {
    width: 220px;
    height: 220px;

    .p-name {
        bottom: 20px;
        font-size: $fz-h4;
    }

    .p-icon {
        width: 72px;
        height: 72px;

        i {
            font-size: 2.25rem;
            margin: auto;
        }

        .preloader-wrapper {
            &.small {
                width: 36px;
                height: 36px;
            }
        }
    }
}

.alt-text,
.alt-icon {
    width: 112px;
    height: 112px;
    line-height: 112px;
    margin: 37px auto 0;

    .material-icons,
    .icon {
        font-size: 3rem;
        line-height: 108px;
    }
}

.alt-icon {
    &.material-icons,
    &.icon {
        font-size: 3rem;
        line-height: 112px;
    }
}

.alt-text.material-icons {
    font-size: 3rem;
    line-height: 108px;
}

.info-panel {
    .right {
        h1 {
            margin-bottom: 1rem;
            line-height: $fz-h1;
            font-size: $fz-h1;
        }

        h3 {
            margin-bottom: 0;
            line-height: 2rem;
            font-size: $fz-h3;
        }
    }
}

table.table-vert {
    font-size: $fz-h3;
    line-height: 2rem;

    thead {
        tr {
            padding: 0 40px 0 0;
        }
    }
}

.player-container {
    height: 77px;

    .player {
        .player-inner {
            font-size: 1rem;
            margin-right: 0;

            .player-bar {
                height: 6px;
                margin-bottom: 16px;

                span {
                    height: 12px;
                    width: 12px;
                    margin-left: -6px;
                }
            }
        }

        .material-icons {
            font-size: 48px;
        }
    }
}

.video-box {
    background: $gray-mid;
    position: relative;
    display: inline-block;

    .media-loading {
        font-size: 1rem;
        bottom: 2px;
    }

    .video-controls {
        .video-time {
            width: 70px;
            font-size: 1rem;
        }

        .video-progress-wrapper {
            width: calc(100% - 140px);

            .video-progress {
                height: 6px;

                .progress-bar {
                    height: 6px;
                    top: 11px;
                }

                .progress-slider {
                    top: 8px;
                    width: 12px;
                    height: 12px;
                    margin-left: -6px;
                }
            }
        }
    }
}

.stroke-info {
    h1 {
        line-height: $fz-h1;
        font-size: $fz-h1;
        margin-bottom: 1rem;
    }

    h3 {
        p {
            margin-bottom: 0;
            font-size: $fz-h3;
            line-height: 2rem;
        }
    }
}

.attach-removed {
    .removed-scrim {
        .removed-scrim_inner {
            font-size: 1.25rem;
        }
    }
}

.modal.share-dev-modal {
    .modal-footer {
        .btn {
            &:first-child {
                margin-bottom: 1rem;
            }
        }
    }
}

/*header*/
header.navbar {
    nav {
        line-height: 70px;
        height: 70px;

        .brand-logo {
            img {
                margin-top: 10px;
                height: 50px;
            }
        }

        ul {
            a:not(.btn),
            a:hover:not(.btn),
            a:active:not(.btn),
            a:focus:not(.btn) {
                font-size: 24px;
                height: 70px;

                .material-icons {
                    line-height: 70px;
                    vertical-align: -9px;
                }
            }

            .navbar-logo {
                height: 70px;
                line-height: 70px;
            }
        }

        [class*=' icon-'].layered,
        [class^='icon-'].layered {
            width: 36px;
            height: 36px;
            font-size: 36px;
        }

        i,
        [class^='mdi-'],
        [class*='mdi-'],
        i.material-icons {
            font-size: 36px;
            vertical-align: -9px;
        }

        .dropdown-content {
            width: 360px !important;
            line-height: 2rem;

            &.block-content {
                ul {
                    li {
                        a:not(.btn),
                        a:not(.btn):hover,
                        a:not(.btn):focus,
                        a:not(.btn):active {
                            padding: 1rem 0.5rem 1rem 1.5rem;

                            i {
                                line-height: 2rem;
                                margin: 0;
                            }
                        }
                    }
                }
            }

            .icon-ethernet {
                font-size: 45px;
                margin-top: 5px;
            }

            i {
                line-height: inherit;
                height: auto;
            }

            a:not(.btn) {
                line-height: 2rem;
                padding: 0;
                color: $link-color;
                height: auto;

                &:hover,
                &:active,
                &:focus {
                    color: $primary-hover;
                    height: auto;
                }

                .check {
                    display: none;
                }

                &.active {
                    color: $primary !important;

                    .check {
                        display: block;
                    }
                }
            }
        }

        ul.dropdown-content {
            width: 360px !important;

            li {
                a:not(.btn),
                a:hover:not(.btn),
                a:active:not(.btn),
                a:focus:not(.btn) {
                    padding: 1rem 1.5rem;

                    i.power {
                        line-height: inherit;
                    }
                }
            }
        }
    }
}

avz-battery-manager {
    [class*=' icon-'].layered,
    [class^='icon-'].layered {
        line-height: 70px;
        display: inline-block;
        margin-top: 17px;

        &:before {
            position: absolute;
            line-height: 36px;
        }

        .path1,
        .path2,
        .path3 {
            line-height: 36px;

            &:before {
                line-height: 36px;
            }
        }
    }

    .battery-text {
        display: inline-block;
        vertical-align: 10px;
        margin-right: 0.5rem;
    }
}

.login-nav {
    right: 80px;
    width: 500px;

    .menu {
        height: 70px;
        line-height: 70px;
    }

    ul {
        a:not(.btn),
        a:hover:not(.btn),
        a:active:not(.btn),
        a:focus:not(.btn) {
            font-size: 24px;
            height: 70px;
        }
    }

    i,
    [class^='mdi-'],
    [class*='mdi-'],
    i.material-icons {
        font-size: 36px;
        vertical-align: -9px;
    }

    ul.dropdown-content {
        width: 360px !important;
        line-height: 2rem;

        a:not(.btn) {
            line-height: 2rem;
            padding: 0;
            color: $link-color;
            height: auto;

            &:hover,
            &:active,
            &:focus {
                color: $primary-hover;
                height: auto;
            }

            .check {
                display: none;
            }

            &.active {
                color: $primary !important;

                .check {
                    display: block;
                }
            }
        }

        li {
            a:not(.btn),
            a:hover:not(.btn),
            a:active:not(.btn),
            a:focus:not(.btn) {
                padding: 1rem 1.5rem;
            }
        }
    }
}

.card.dashboard-card {
    border-width: 10px;
    padding: 2rem 2rem 1.5rem;

    h3 {
        font-size: $fz-h2;
        margin-bottom: 1rem;
    }

    .chip {
        width: 48px;
        height: 48px;

        img {
            width: 48px;
            height: 48px;
        }

        .dot {
            width: 1rem;
            height: 1rem;
        }
    }
}

/*Modals*/
.modal {
    border-radius: 8px;

    &.modal-xsmall {
        width: 750px;
    }

    .modal-content {
        padding: 2rem 2.25rem 0;
        line-height: 2rem;

        h4 {
            font-size: $fz-h3;
            margin-bottom: 2rem;

            .material-icons {
                margin-right: 1.5rem;
                font-size: 2rem;
                vertical-align: -6px;
            }
        }

        p {
            font-size: $fz-h4;
            margin-bottom: 2rem;
        }
    }

    .modal-footer {
        padding: 1.5rem 2.25rem;
    }
}

.card.waiting-card {
    height: 525px;
    padding: 90px;
    font-size: 36px;

    .material-icons {
        font-size: 72px;
    }

    .status {
        font-size: 26px;
    }

    .timer {
        font-size: 55px;
    }
}

.large-change {
    &.badge {
        font-size: 1.125rem;
        padding: 0 1.5rem;
        line-height: 36px;
    }

    &.m-t1 {
        margin-top: $space-2 !important;
    }

    &.m-r2 {
        margin-right: $space-15 !important;
    }

    &.m-t15,
    &.m-t2 {
        margin-top: $space-3 !important;
    }

    &.card-content {
        padding: 1.5rem 2rem;
    }

    &.material-icons {
        font-size: 48px;
    }
}

/*feedback*/
.card {
    border-radius: 4px;
}

.feedback {
    h1 {
        font-size: 3.5rem;
    }

    .card h4 {
        font-size: 24px;
    }
}

.feedback-circle {
    width: 224px;
    height: 224px;
    margin: 70px 40px;

    .material-icons {
        font-size: 112px;
        line-height: 220px;
    }

    &.sm {
        width: 78px;
        height: 78px;

        .material-icons {
            line-height: 74px;
            font-size: 40px;
        }

        &.third {
            .na {
                line-height: 70px;
            }
        }
    }
}

.feedback-clicked {
    .feedback-circle {
        -webkit-transform: translateX(155px);
        -moz-transform: translateX(155px);
        transform: translateX(155px);

        &.good {
            -webkit-transform: translateX(155px) rotate(180deg);
            -moz-transform: translateX(155px) rotate(180deg);
            transform: translateX(155px) rotate(180deg);
        }

        &:nth-child(2) {
            -webkit-transform: translateX(-155px);
            -moz-transform: translateX(-155px);
            transform: translateX(-155px);

            &.good {
                -webkit-transform: translateX(-155px);
                -moz-transform: translateX(-155px);
                transform: translateX(-155px);
            }

            &.bad {
                -webkit-transform: translateX(-155px) rotate(180deg);
                -moz-transform: translateX(-155px) rotate(180deg);
                transform: translateX(-155px) rotate(180deg);
            }
        }

        &.sm {
            -webkit-transform: translateX(49px);
            -moz-transform: translateX(49px);
            transform: translateX(49px);

            &.good {
                -webkit-transform: translateX(49px) rotate(180deg);
                -moz-transform: translateX(49px) rotate(180deg);
                transform: translateX(49px) rotate(180deg);
            }

            &:nth-child(2) {
                -webkit-transform: translateX(-49px);
                -moz-transform: translateX(-49px);
                transform: translateX(-49px);

                &.good {
                    -webkit-transform: translateX(-49px);
                    -moz-transform: translateX(-49px);
                    transform: translateX(-49px);
                }

                &.bad {
                    -webkit-transform: translateX(-49px) rotate(180deg);
                    -moz-transform: translateX(-49px) rotate(180deg);
                    transform: translateX(-49px) rotate(180deg);
                }
            }

            &.third {
                -webkit-transform: translateX(96px);
                -moz-transform: translateX(96px);
                transform: translateX(96px);

                &.good {
                    -webkit-transform: translateX(96px) rotate(180deg);
                    -moz-transform: translateX(96px) rotate(180deg);
                    transform: translateX(96px) rotate(180deg);
                }

                &.n-a {
                    -webkit-transform: translateX(96px);
                    -moz-transform: translateX(96px);
                    transform: translateX(96px);
                }

                &:nth-child(2) {
                    transform: none;
                }

                &:nth-child(3) {
                    -webkit-transform: translateX(-96px);
                    -moz-transform: translateX(-96px);
                    transform: translateX(-96px);

                    &.good {
                        -webkit-transform: translateX(-96px);
                        -moz-transform: translateX(-96px);
                        transform: translateX(-96px);
                    }

                    &.bad {
                        -webkit-transform: translateX(-96px) rotate(180deg);
                        -moz-transform: translateX(-96px) rotate(180deg);
                        transform: translateX(-96px) rotate(180deg);
                    }
                }
            }
        }
    }
}

/*intake forms*/
.form-top-bar {
    h4 {
        font-size: 2rem;
    }

    border: 2px solid $gray-light;
    border-left: none;
    padding: 0 2rem;
    min-height: 100px;

    .flex-auto {
        padding: 1rem 0;
    }

    .switch {
        margin-left: 7.5rem;
    }

    .switch.simple label .lever:before,
    .switch.guide label .lever:before,
    .switch.simple label input[type='checkbox']:checked + .lever:before,
    .switch.guide label input[type='checkbox']:checked + .lever:before {
        font-size: 1.5rem;
        width: 71px;
        left: -95px;
        top: auto;
        line-height: 64px;
    }
}

.progress {
    height: 8px;
}

.card.card-form {
    .collapsible {
        .collapsible-header.fullwidth {
            font-size: 1.5rem;
            line-height: 4.5rem;
            min-height: 4.5rem;
            padding: 0 2rem;
        }

        .collapsible-body.content-body {
            padding: 0 2rem;
        }
    }
}

.priority-select {
    li {
        .material-icons {
            line-height: 4rem;
            font-size: 2rem;
        }

        .double-ar {
            margin-top: 14px;
        }
    }
}

.form {
    .input-field .select-wrapper {
        & + label {
            font-size: 1.25rem;
            top: -19px;
        }
    }

    .input-field.select-empty {
        .select-wrapper + label {
            font-size: 1.5rem;
            top: 0.8rem;

            &.active {
                font-size: 1.25rem;
                top: -19px;
            }
        }
    }
}

.form-bottom-bar {
    padding: 0 2rem;

    .flex-auto {
        padding: 2rem 0;
    }

    .allert-info i {
        font-size: 2.5rem;
        vertical-align: -10px;
        margin-right: 1.5rem;
    }

    .btn-flat,
    .btn {
        .material-icons {
            font-size: 2.5rem;
            margin-right: 1.25rem;
            vertical-align: -11px;
            line-height: 1;
        }
    }

    button:last-child {
        margin-left: 1.5rem;
    }
}

.guide-form {
    margin: 92px auto;
    min-width: 700px;

    h3 {
        margin-bottom: 80px !important;
        font-size: $fz-h2;
    }

    p.skip-hint {
        margin-bottom: 2rem;
    }

    .row {
        margin-bottom: 4.25rem;
        min-width: 700px;
    }

    .guide-btn-next {
        margin-top: 1rem;
    }

    .input-field textarea.bordered-textarea {
        margin-top: -1rem;
    }
}

/*login*/
.card.login-card {
    width: 790px;
    padding: 40px;

    h4 {
        font-size: $fz-h3;
    }

    .link-block {
        .text-box {
            .h3 {
                font-size: $fz-h2;
                margin-bottom: 1rem;
            }
        }
    }

    .card-actions {
        padding-top: 30px;

        .btn,
        .btn-flat {
            margin-left: 1rem;

            &.left {
                margin: 0;
            }
        }
    }
}

.select-wrapper {
    .dropdown-content {
        &.wifi-select {
            .material-icons {
                line-height: 64px;
                margin-right: 2rem;
            }
        }
    }
}

/*qr*/
.qr-wrapper {
    h1 {
        font-size: $fz-h1;
        margin-top: 1.5rem;

        span {
            font-size: 20px;
        }
    }

    .text-center .btn {
        & + .btn {
            margin-left: 0.5rem;
        }
    }
}

.wired-subtitle {
    min-width: 135px;
}

.ip-config {
    .row {
        &.wired-block {
            margin-bottom: 0;
        }

        .top-indent {
            margin-top: 0;

            .input-field {
                margin-top: 0;

                .js-keyboard-input {
                    margin: 0 0 3rem;
                }
            }
        }
    }
}

button.icon-link {
    height: auto;

    &.cart-normal {
        .material-icons {
            @media #{$xxlarge-and-up} {
                height: 4.5rem;
                width: 4.5rem;
                font-size: 2.25rem;
                line-height: 4.5rem;
            }
        }
    }
}

.modal {
    .modal-content.fixed-size {
        height: 600px;
    }

    .modal-footer {
        p {
            line-height: 72px;
            margin-left: -1rem;
            font-size: 20px;
        }
    }
}

.modal-header {
    h4 {
        font-size: 28px;

        .material-icons {
            font-size: 36px;
            vertical-align: -7px;
            margin-right: 5px;
        }
    }
}

.collapsible-body.content-body {
    padding-bottom: 2rem;
}

.modal .modal-content p.form-section-title {
    font-size: 24px;
    margin-top: 3rem;
}

.collapsible-header {
    font-size: 24px;
    line-height: 4.5rem;
    min-height: 4.5rem;

    i {
        font-size: 48px;
        line-height: 4.5rem;
        width: auto;
    }
}

.modal.modal-lg {
    width: 1120px;
}

.form {
    .input-field {
        margin-top: 2rem;

        .dot-form-divider {
            width: 4px;
            height: 4px;
            left: -2px;
            top: calc(3.5rem - 2px);
        }

        textarea.bordered-textarea {
            height: 170px;
            padding-top: 2.5rem;
            padding-bottom: 2rem;
            padding-left: 1rem;

            & + label {
                padding-top: 0.7em;
                left: 2px;
                top: 1px;
                border-top: 1px solid $white;
                transition-property: padding, font-size;

                &.active {
                    padding-top: 0.3em;
                }
            }

            &:focus {
                & + label {
                    border-top: 1px solid $primary;
                }
            }

            &:hover {
                & + label {
                    border-top: 1px solid $gray-mid;
                }
            }
        }

        .dropdown-content {
            top: -10px !important;
        }

        .dropdown-content.no-label {
            top: 0 !important;
        }
    }
}

.modal .modal-content.large-styles,
.large-styles {
    p {
        font-size: 24px;
        line-height: 39px;
    }

    font-size: 24px;
    line-height: 39px;
}

.modal .modal-content.large-styles {
    padding: 2rem;
}

.dropdown-content li > a,
.dropdown-content li > span {
    font-size: 24px;
    line-height: 26px;
    padding: 19px 24px;
}

/*settings*/
h4.section-title {
    .material-icons {
        font-size: 36px;
    }
}

.add-block {
    .btn.m-t3 {
        margin-top: 1rem !important;
    }

    p {
        margin-top: 1rem;
        margin-bottom: 1.5rem;
    }
}

.cam-preview {
    min-height: 300px;

    .btn-wrapper {
        bottom: 0.5rem;
    }

    .material-icons {
        font-size: 76px;
    }
}

p.label {
    font-size: 1.25rem;
}

.material-tooltip {
    margin-top: 17px;
    padding: 15px 18px;

    span {
        font-size: 20px;
    }
}

.panel .panel-body {
    padding: 30px;

    h3 {
        font-size: 36px;
        margin-bottom: 1.5rem !important;
    }
}

table tbody.simple-text tr td {
    height: 80px;
}

.noUi-connect {
    height: 4px;

    .noUi-origin {
        bottom: 8px;
    }

    &.noUi-horizontal .noUi-handle {
        width: 24px;
        height: 24px;
        top: -10px !important;

        &.noUi-active:after {
            width: 46px;
            height: 46px;
        }
    }
}

.navbar nav {
    height: 70px;
    line-height: 70px;

    ul a {
        padding: 0 24px;

        i.material-icons {
            line-height: 70px;
        }

        .icon.battery {
            font-size: 48px;
            vertical-align: -12px;
        }
    }

    ul li {
        height: 70px;
        min-width: 70px;
    }
}

.wifi-icon {
    display: block;

    .icon {
        font-size: 36px;
        width: 36px;
        height: 36px;
        vertical-align: -7px;
    }
}

header nav .dropdown-content a:not(.btn).active .check {
    color: $primary;
}

header nav .dropdown-content .list-styled li a:active:not(.btn),
header nav .dropdown-content .list-styled li a:focus:not(.btn),
header nav .dropdown-content .list-styled li a:hover:not(.btn),
header nav .dropdown-content .list-styled li a:not(.btn) {
    padding: 1rem 1.5rem;
}

header nav .dropdown-content .list-styled.wifi-list li .material-icons.check {
    line-height: 1;
    color: $primary;
}

.avz-dialog.avz-dialog-theme-avizia.modal-sm .avz-dialog-content {
    width: 800px;
    font-size: 24px;
}

.avz-dialog.avz-dialog-theme-avizia.modal-lg .avz-dialog-content {
    width: 1000px;

    .advanced-connectivity {
        avz-button-component:last-child {
            button {
                margin: 1rem 0 0 !important;
            }
        }
    }
}

.avz-dialog.avz-dialog-theme-avizia.modal-xlg .avz-dialog-content {
    width: 1200px;
}

.select-wrapper input.select-dropdown {
    height: 65px;
}

.form .input-field .select-wrapper + label {
    top: 13px;
    line-height: normal;
}

/*avz-player*/

.settings-logo {
    width: 200px;
}

.avz-player {
    .player-inner {
        font-size: 1.3125rem;

        .player-bar {
            height: 4px;
            width: calc(100% - 3.5rem);

            span {
                height: 24px;
                width: 24px;
                top: -10px;
            }
        }
    }

    .player-buttons a {
        height: 3rem;
        margin-bottom: 0;
        margin-top: -0.5rem;

        .material-icons {
            font-size: 3rem;
        }
    }

    .player-conrtols {
        padding-left: 3.5rem;
    }
}

.noUi-background {
    height: 4px;
}

.input-field {
    outline: 0 !important;
}

.form {
    .search-form {
        &.m-r4 {
            margin-right: 5rem !important;
        }

        .input-field {
            margin-top: 0;
        }
    }

    .icon-link.cart-normal.m-t1 {
        margin-top: 0 !important;
    }
}

button.icon-link.cart-normal .material-icons {
    height: 4rem;
    line-height: 4rem;
    width: 4rem;
}

.grid-list-man.grid--width4 .grid-list-item {
    width: calc(100% / 3);

    &.fullwidth {
        width: 100%;
    }
}

.cc-button {
    avz-indicator-component .indicator {
        font-size: 1.125rem;
    }

    .m-b2 {
        margin-bottom: 1.75rem !important;
    }

    .truncate {
        margin-bottom: 0.5rem;
    }

    .rate {
        margin-top: 1rem;

        .material-icons {
            font-size: 21px;
            width: 21px;
            height: 21px;
        }
    }
}

.icon-general-img {
    margin: 0 auto 1.5rem !important;
}

.dash-box {
    h4 {
        font-size: 2.25rem;
        font-weight: 400;
    }

    .select-wrapper input.select-dropdown {
        margin-bottom: 0;
    }

    .select-placeholder {
        position: relative;
    }

    .form .input-field .select-wrapper + label {
        font-size: 1.5rem;
        top: 1.5rem;
        transform: none;
    }

    .form .ng-not-empty {
        .select-wrapper + label {
            transform: translateY(-100%);
            font-size: 20px;
            top: 0.9rem;
        }
    }

    .valign-wrapper {
        .xxl9 {
            width: 66.66667%;
        }

        .xxl3 {
            width: 33.33333%;
        }
    }
}

.avatar.medium {
    width: 96px;
    height: 96px;
}

.m-t2.cart-m-t15 {
    margin-top: 1.5rem !important;
}

.card.dashboard-card.expanded {
    .text-box {
        width: calc(100% - 320px);

        .caption {
            font-size: 18px;
        }

        .quote {
            padding-left: 3rem;
            line-height: 2rem;
            margin-bottom: 1.5rem !important;

            .material-icons {
                font-size: 40px;
            }
        }

        .m-b15 {
            margin-bottom: 2rem !important;
        }
    }

    .image {
        width: 280px;
        height: 280px;
    }

    h4 {
        font-size: 24px;
    }

    .border-bottom {
        margin-top: 1rem;
        padding-bottom: 1rem !important;

        & + .row {
            font-size: 20px;
            line-height: 2rem;
        }
    }

    .close-button .material-icons {
        font-size: 2.25rem;
    }

    .border-top.pt2 {
        padding-top: 1.5rem !important;
    }
}

.indicator {
    font-size: 1.125rem !important;
}

.dot {
    width: 12px;
    height: 12px;
}

.cc-button {
    &.border-tertiary,
    &.border-primary,
    &.border-primary-light,
    &.border-secondary,
    &.border-gray-light,
    &.border-gray-mid,
    &.border-gray-dark,
    &.border-success,
    &.border-warning,
    &.border-danger,
    &.border-pink,
    &.border-purple,
    &.border-deep-purple,
    &.border-indigo,
    &.border-light-blue,
    &.border-teal,
    &.border-green,
    &.border-lime,
    &.border-yellow,
    &.border-orange,
    &.border-blue-grey,
    &.border-white {
        &:after {
            width: 6px !important;
        }
    }

    .dot {
        &.danger {
            background-color: var(--danger) !important;
        }
    }
}

.login-white {
    .py2 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
    }

    .py1 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }

    .px15 {
        padding-left: 2.25rem !important;
        padding-right: 2.25rem !important;
    }

    .px2 {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important;
    }

    h4 {
        font-size: 1.875rem;
    }
}

.centered {
    width: 1200px;
}

.icon-qr-code-activation-successful.white-text,
.icon-qr-code-activation-failure.white-text {
    .path1:before {
        color: $white;
    }
}

.activate-logo {
    margin-bottom: 3rem !important;
}

.navbar nav .nav-wrapper .navbar-logo > img {
    max-height: 51px;
    max-width: 300px;
}

#video-preview-block {
    top: -30px;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: absolute;

    #remotevideo {
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
    }
}

.video-bottomnav.menu-open .menu-button {
    -webkit-transform: translateX(132px);
    -moz-transform: translateX(132px);
    transform: translateX(132px);
    -webkit-transition: transform 0.5s ease;
    -moz-transition: transform 0.5s ease;
    transition: transform 0.5s ease;
}

.video-bottomnav .button-wrap.slide-button {
    animation-duration: 0.5s;
}

.video-bottomnav .button-wrap.slide-button.animated.fadeOutLeft {
    z-index: -10;
    position: relative;
    pointer-events: none;
    cursor: default;
    animation-duration: 0.3s;
}

.companion {
    top: 32px;
    left: 32px;
}

@media (min-width: 1600px) {
    .companion {
        top: 32px;
    }
}

.video-topnav .topnav-left .btn-floating span {
    line-height: 50px;
}

.video-topnav .topnav-left .btn-floating.self-off {
    background: transparent !important;
    box-shadow: none;
    z-index: 99;
}

.video-topnav .topnav-left .btn-floating {
    transition: none !important;
}

.dashboard-wrapper {
    height: 100%;

    & > .flex {
        height: auto;
    }
}

.settings-block {
    h2 {
        font-weight: normal;
    }
}

.head-gradient {
    &:before {
        content: '';
        height: 200px;
        background: linear-gradient(
            to right,
            #0469bd 0%,
            #25abfd 100%
        ) !important;
        width: 100%;
        display: block;
        position: absolute;
        left: 0;
        top: 0;
    }
}

.bottom-border {
    border-bottom: 1px solid $gray-light;
}

.form-head {
    position: relative;
    z-index: 2;
}

.page-wrap {
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .page-scroll {
        overflow: auto;
    }
}

.with-sticky {
    margin-bottom: 108px !important;
    z-index: 1;
}

.h100vh {
    height: 100vh;
}

#video-screen-keyboard {
    margin-bottom: -120px;
    margin-top: 100px;
}

.sticky-margin {
    margin-bottom: 38px !important;
}

.progress-button .content:after,
.progress-button .content:before {
    line-height: 72px;
}

.ngdialog.ngdialog-theme-default .ngdialog-content {
    width: 750px !important;
    padding: 2rem 2.25rem !important;
    font-size: 1.5rem !important;
    font-family: inherit !important;

    .icon {
        font-size: 120px;
    }

    h3 {
        font-weight: 700;
    }

    .ngdialog-close {
        font-size: 24px;
        border-radius: 1000px;
        height: 4.5rem;
        width: 4.5rem;
        font-size: 2.25rem;
        line-height: 4.5rem;
        top: 0.5rem;
        right: 0.5rem;
        background: none;

        &:before {
            padding: 0 !important;
            font-size: inherit !important;
        }

        &:active {
            background: rgba($black, 0.12);
        }
    }
}

.module-title {
    height: auto;
    line-height: 78px;
    margin-left: 4.5rem;

    .icon {
        font-size: 4.5rem;
        margin-right: 1.5rem;
        vertical-align: -24px;

        @media #{$xxlarge-and-up} {
            vertical-align: -20px;
        }
    }

    &.no-cancel-button {
        margin-left: 0;
    }
}

.dash-box {
    .gray-mid-text {
        font-size: 1.125rem;
    }
}

.network-page {
    padding: 0;

    .lost-connection-screen {
        padding-bottom: 0;
        padding-top: 0;

        .white-box {
            margin: 0;
            background: unset;
            box-shadow: none;
            padding: 0 !important;
        }
    }
}

button.modal-trigger {
    z-index: 1 !important;
}

.m-bot-cust {
    margin: 0 0 0.75rem;
}

.pb85 {
    padding-bottom: 8.5rem;
}

.shared-device-holder {
    width: 100%;
    height: 540px;
    padding: 25px 60px 0;
    border: 1px solid $gray-mid;
    background: $gray-dark;
    background-size: cover;
    background-position: center;
    margin-left: 8px;

    > * {
        width: 100%;
    }

    h2 {
        font-size: 48px;
        color: $white;
        text-align: center;
        margin-bottom: 24px;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
            font-size: 80px;
        }
    }

    .parah-holder {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 32px;

        i {
            font-size: 48px;
            color: #fff;
        }

        p {
            font-size: 24px;
            color: $white;
            line-height: 32px;
            text-align: left;
            margin-bottom: 0 !important;
        }
    }

    .record-state {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;

        p {
            font-size: 24px;
            line-height: 32px;
        }

        .primary-jumbo {
            background-color: $primary !important;
            border-color: $primary !important;
        }
    }

    .link {
        color: #0e62b8;
        display: inline-flex;

        &:hover {
            text-decoration: none;
            color: $primary;
        }
    }

    .radio [type='radio'] + label:after,
    .radio [type='radio'] + label:before {
        width: 24px;
        height: 24px;
        top: 1px;
    }

    .checkbox {
        .helper {
            &:before {
                box-shadow: none;
                top: 16px;
                left: 8px;
            }

            &:after {
                top: 10px;
                left: 2px;
            }
        }

        .hover {
            left: -8px;
        }
    }

    .aw-slider.with-icons .noUi-target {
        width: calc(100% - 46px) !important;
        margin-left: 48px;
    }
}

.menu-panel {
    .chip-jumbo-flat.disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}

.steth-image {
    max-height: 104px !important;

    img {
        max-height: 104px !important;
    }
}

.search-provider {
    .cc-button:hover {
        border-color: $gray-light;
    }

    .cc-button:focus {
        border-color: $green;
    }
}

.notifi-bar {
    position: absolute;
    width: 100%;
    z-index: 99;
}

.video-inner {
    div.notted + div {
        padding: 70px 20px 0 !important;

        .topnav-left {
            .companion {
                margin-top: 40px;
            }
        }
    }
}

.preview-container.content {
    video {
        height: 100%;
        position: relative;
        z-index: 9;
    }
}

.input-field {
    .customisable-dropdown {
        a.customisable-dropdown-selection {
            font-size: 1.5rem;
            height: 65px;
            line-height: 55px;
        }
    }
}

.input-field.select-placeholder
    .select-wrapper.customisable-dropdown
    + label.active {
    font-size: 1.25rem;
    top: -7px;
    line-height: 30px;
}

.input-field.select-placeholder .select-wrapper.customisable-dropdown + label {
    font-size: 1.5rem;
    line-height: 33px;
}

.modal-h {
    position: relative;
    height: 100%;

    .btn-back-revers {
        position: absolute;
        top: 1.5rem;
        right: 56px;
    }
}

.lock-screen {
    .btn-back-revers {
        position: absolute;
        top: 1.5rem;
        right: 56px;
    }

    a {
        font-size: 16px;

        &:hover {
            text-decoration: none;
        }
    }

    .pin-code {
        .circle {
            background: $white;
            display: block;
            width: 32px;
            height: 32px;
            opacity: 0.4;

            &.active {
                opacity: 1;
            }
        }
    }

    .backspace {
        color: $white;
        opacity: 0.4;

        &.active {
            opacity: 1;
        }
    }
}

.pin-screen {
    transition: 0.5s;

    h1 {
        font-weight: $fw-normal;
    }

    .btn-back-revers {
        position: absolute;
        top: 2rem;
        right: 95px;
    }

    .x-icon {
        font-size: 2.5rem;

        &:hover {
            text-decoration: none;
        }
    }

    .x-mark {
        font-size: 3rem;
        margin-top: 2px;
    }

    .pin-code {
        &:focus {
            outline: none;
        }

        outline: none;

        .circle {
            background: $gray-mid;
            display: block;
            width: 32px;
            height: 32px;
            opacity: 0.4;

            &.active {
                opacity: 1;
                background: $primary;
            }
        }

        .backspace {
            color: $gray-mid;
            opacity: 0.4;

            &.active {
                opacity: 1;
                color: $primary;
            }
        }
    }
}

.dashboard-locker {
    .training-room {
        width: 1200px;
        height: 650px;
        padding-top: 33px;
        padding-bottom: 48px;

        .portal-logo {
            margin-bottom: 60px;
            z-index: 1;
            position: relative;
            max-height: 90px;
            height: 100%;
        }

        .black-custom-icon {
            margin-top: -80px;
        }

        .charge-block {
            padding-bottom: 48px;
        }
    }

    .battery-blue {
        width: 80px !important;
        height: 70px !important;
        font-size: 70px !important;

        .path1:before {
            color: $primary !important;
        }

        .path2:before {
            color: $primary !important;
        }
    }

    .header-gray {
        height: 160px;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
    }

    header {
        position: absolute;

        nav {
            padding-right: 3.5rem;
            background: transparent;

            ul {
                a {
                    &:hover {
                        background: transparent;
                    }
                }
            }

            ul.dropdown-conten {
                margin-right: 3.5rem;
            }
        }
    }
}

.caller-white-block {
    width: 520px;
    height: 770px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background: $white;

    .caller-info {
        margin-top: 3rem;
    }

    .call-buttons {
        margin-top: 2rem;

        h4 {
            color: $gray-dark;
        }
    }

    .ripple-wave::after,
    .ripple-wave::before {
        background-color: $primary;
    }

    .ripple-wave .ripple-wave-2::before {
        background-color: $primary;
    }

    avz-caller-section {
        color: $gray-dark !important;
    }
}

[type='radio'] + label:before,
[type='radio'] + label:after {
    content: '';
    width: 24px;
    height: 24px;
}

.radio .hover {
    top: -8px;
    left: -12px;
}

.delete-height {
    height: 2rem;

    i {
        font-size: 2rem;
    }
}

.no-device-height {
    line-height: 2.3rem;
}

.dashboard-locker {
    .navbar {
        nav {
            ul {
                li {
                    list-style-type: none;
                }
            }
        }
    }
}

.error-dang-text {
    position: absolute;
    bottom: -70px;
    width: max-content;
    margin: 0;
    color: var(--danger);
}

.intm-home-screen {
    position: absolute;
    right: 0;

    nav {
        background: none !important;

        .nav-wrapper {
            border-bottom: none;
        }
    }
}

.powered-by {
    width: 240px;
    position: absolute;
    bottom: 15px;
    padding-left: 3.5rem;
}

.band-text {
    margin-bottom: 0;
    line-height: 2rem;
}

.no-dash-footer {
    h3 {
        font-size: 1.9rem;
    }

    .re-sized {
        width: 210px;
    }
}

.autoanswer-state {
    font-size: 2rem;
    font-weight: 400;
}

.custom-upload-icon {
    width: 3rem;
    font-size: 3rem;
    display: block;
    margin: 10px auto;
}

.custom-upload-txt {
    display: block;
    margin-bottom: 10px;
}

.scrollable-wifi-settings {
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 1.5rem;
    background: transparent;
    -ms-overflow-style: none;
    /* IE 10+ */
    scrollbar-width: none;
    /* Firefox */
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

/* Header Wi-Fi connection*/

.dashboard-connection {
    .icon-baseline-signal_wifi_0_bar-24px:before,
    .icon-baseline-signal_wifi_1_bar-24px .path1:before,
    .icon-baseline-signal_wifi_1_bar-24px .path2:before,
    .icon-baseline-signal_wifi_2_bar-24px .path1:before,
    .icon-baseline-signal_wifi_2_bar-24px .path2:before,
    .icon-baseline-signal_wifi_3_bar-24px .path1:before,
    .icon-baseline-signal_wifi_3_bar-24px .path2:before,
    .icon-baseline-signal_wifi_4_bar-24px:before,
    .icon-ethernet:before {
        color: $gray-mid;
    }
}

.empty-dashboard-connection {
    .icon-baseline-signal_wifi_0_bar-24px:before,
    .icon-baseline-signal_wifi_1_bar-24px .path1:before,
    .icon-baseline-signal_wifi_1_bar-24px .path2:before,
    .icon-baseline-signal_wifi_2_bar-24px .path1:before,
    .icon-baseline-signal_wifi_2_bar-24px .path2:before,
    .icon-baseline-signal_wifi_3_bar-24px .path1:before,
    .icon-baseline-signal_wifi_3_bar-24px .path2:before,
    .icon-baseline-signal_wifi_4_bar-24px:before,
    .icon-ethernet:before {
        color: $white;
    }
}

#netIcon {
    line-height: 70px;
}

@keyframes dark-bg-opacity {
    0% {
        background: rgba(0, 0, 0, 1);
    }

    30% {
        background: rgba(0, 0, 0, 0.8);
    }

    80% {
        background: rgba(0, 0, 0, 0.8);
    }

    100% {
        background: rgba(0, 0, 0, 0);
    }
}

@keyframes primary-bg-opacity {
    0% {
        background: rgba(23, 116, 204, 1);
    }

    80% {
        background: rgba(23, 116, 204, 1);
    }

    100% {
        background: rgba(23, 116, 204, 0);
    }
}

@keyframes logo-opacity {
    0% {
        opacity: 0;
    }

    20% {
        opacity: 0;
    }

    40% {
        opacity: 0.5;
    }

    50% {
        opacity: 1;
    }

    60% {
        opacity: 1;
    }

    80% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

@keyframes text-opacity {
    0% {
        opacity: 0;
    }

    30% {
        opacity: 0;
    }

    50% {
        opacity: 0.5;
    }

    60% {
        opacity: 1;
    }

    80% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

.welcom-animation-screen {
    z-index: 999;
    -webkit-animation: primary-bg-opacity 5s ease-in-out;
    -moz-animation: primary-bg-opacity 5s ease-in-out;
    -o-animation: primary-bg-opacity 5s ease-in-out;
    animation: primary-bg-opacity 5s ease-in-out;

    .dark-overlay {
        -webkit-animation: dark-bg-opacity 5s ease-in-out;
        -moz-animation: dark-bg-opacity 5s ease-in-out;
        -o-animation: dark-bg-opacity 5s ease-in-out;
        animation: dark-bg-opacity 5s ease-in-out;
    }

    .welcome-animation-logo {
        -webkit-animation: logo-opacity 5s ease-in-out;
        -moz-animation: logo-opacity 5s ease-in-out;
        -o-animation: logo-opacity 5s ease-in-out;
        animation: logo-opacity 5s ease-in-out;
        width: 460px;
    }

    h2 {
        -webkit-animation: text-opacity 5s ease-in-out;
        -moz-animation: text-opacity 5s ease-in-out;
        -o-animation: text-opacity 5s ease-in-out;
        animation: text-opacity 5s ease-in-out;
    }
}

.settings-pin {
    z-index: 1 !important;

    .avz-dialog-content {
        width: 97% !important;
        max-height: 95% !important;
    }

    .avz-dialog-content.in-content {
        max-width: 100% !important;
        max-height: 100% !important;
        width: 100% !important;
    }

    .fix-width {
        width: 636px;
        margin-left: auto;
        margin-right: 177px;
    }

    .fix-width-radio {
        width: 636px;
        display: flex;
        justify-content: space-between;
        margin-left: auto;
        margin-right: 177px;
    }

    .avz-dialog-close {
        display: none;
    }
}

.ov-hid {
    .avz-dialog-content {
        overflow: hidden !important;
    }
}

.btn-danger.no-hover:hover,
.btn-danger.no-hover:focus {
    background: $danger;
}

.flex-imp {
    display: flex !important;
}

.recording-dot {
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 1000px;
    background-color: $danger;
}

.scrollable-staticip-settings {
    height: 395px !important;
}

.scrollable-wired-settings {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 400px;
    padding: 0 1rem;
}

.wired-settings-default {
    padding: 0 1rem;
    width: 100%;
}

.wired-ip-width {
    width: 33.33333% !important;
}

.device-confirm {
    width: 80vw;
}

.dash-box .input-field-new.has-label .input-field-float-label {
    top: 0;
}

.dashboard-card {
    input {
        &.form-control {
            margin: 0;
        }
    }
}
