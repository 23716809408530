/* Atom styles */
@import 'base';
@import 'custom';
@import 'dashboard';
@import 'feedback';
@import 'login';
@import 'settings';
@import 'intake-forms';
@import 'buttons';
@import 'snackbar';

// For v10 and v11 calls
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
