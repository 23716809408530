// Import Third Party Styles from video-plugin-tpc
@import '~@aw/video-plugin-tpc/styles';

// Please if possible avoid using @import "~@aw-hospital/aw-styles/stylesheets/general", use instead @import by pieces for only required functionality
@import '~@aw-hospital/aw-styles/stylesheets/modules/_common.scss';
@import '~@aw-hospital/aw-styles/stylesheets/themes/theme-default/common';
@import '~@aw-hospital/aw-styles/stylesheets/components/fonts';
@import '~@aw-hospital/aw-styles/stylesheets/components/grid-lists';
@import '~@aw-hospital/aw-styles/stylesheets/components/microphone-equalizer';

// Modules
@import '@aw-hospital/aw-styles/stylesheets/modules/avatars.scss';
@import '~@aw-hospital/aw-styles/stylesheets/modules/buttons.scss';
@import '~@aw-hospital/aw-styles/stylesheets/modules/checkboxes.scss';
@import '~@aw-hospital/aw-styles/stylesheets/modules/chips.scss';
@import '~@aw-hospital/aw-styles/stylesheets/modules/collapsible.scss';
@import '~@aw-hospital/aw-styles/stylesheets/modules/date-time-picker.scss';
@import '~@aw-hospital/aw-styles/stylesheets/modules/dropdown.scss';
@import '~@aw-hospital/aw-styles/stylesheets/modules/input-fields.scss';
@import '~@aw-hospital/aw-styles/stylesheets/modules/input-phone.scss';
@import '~@aw-hospital/aw-styles/stylesheets/modules/keyboard.scss';
@import '~@aw-hospital/aw-styles/stylesheets/modules/modal-dialogs.scss';
@import '~@aw-hospital/aw-styles/stylesheets/modules/preloader-progress.scss';
@import '~@aw-hospital/aw-styles/stylesheets/modules/radio-buttons.scss';
@import '~@aw-hospital/aw-styles/stylesheets/modules/slider.scss';
@import '~@aw-hospital/aw-styles/stylesheets/modules/switches.scss';
@import '~@aw-hospital/aw-styles/stylesheets/modules/tabs.scss';
@import '~@aw-hospital/aw-styles/stylesheets/modules/test-sound.scss';

@import './general_all_theme';

.nova-web-clinical-theme {
    @import './components/index';
}

.theme-light {
    @import './components/index';
}
