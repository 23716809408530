.card {
    border-radius: 2px;
}

.feedback {
    overflow-y: auto;
    height: 100%;

    .justify-between {
        padding: 70px;
        min-height: 100%;
    }

    .feedback-inner {
        max-height: 0;
        overflow: hidden;

        &.active {
            max-height: 1500px;
            transition: max-height 1s cubic-bezier(0.4, 0, 0.2, 1);

            .row.animated {
                animation-delay: 0.4s;
            }
        }
    }

    .buttons {
        text-align: center;
    }

    .card {
        padding: 1.5rem 1.5rem 2rem;
        margin: 0 0 40px;

        .feedback-circle {
            color: $primary;
            border-color: $primary;
            vertical-align: top;

            &:hover {
                background: rgba($primary, 0.12);
            }
        }

        .input-field textarea.bordered-textarea {
            margin-bottom: 0;
        }
    }
}

.btn-flat.btn-white,
a.btn-flat.btn-white:not(.btn) {
    color: $white;
    box-shadow: none !important;

    &:hover {
        background: rgba($white, 0.12);
        color: $white;
    }

    &:active {
        background: rgba($white, 0.24);
    }
}

.feedback-circle {
    width: 120px;
    height: 120px;
    border: 2px solid $white;
    text-align: center;
    border-radius: 1000px;
    color: $white;
    margin: 70px 0;
    background: none;
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    transform: translateX(0px);
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    transition: all 0.5s ease;
    transition-property: transform, opacity;
    outline: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba($white, 0);

    .material-icons {
        font-size: 60px;
        line-height: 116px;
    }

    &.selected {
        z-index: 1;
        position: relative;

        &.bad {
            color: $danger !important;
            border-color: $danger !important;

            &:hover {
                background: rgba($danger, 0.12) !important;
            }
        }

        &.good {
            color: $success !important;
            border-color: $success !important;

            &:hover {
                background: rgba($success, 0.12) !important;
            }
        }

        &.n-a {
            color: $gray-dark !important;
            border-color: $gray-dark !important;

            &:hover {
                background: rgba($gray-dark, 0.12) !important;
            }
        }
    }

    &.sm {
        width: 56px;
        height: 56px;
        margin: 0;
        background: none;

        .material-icons {
            line-height: 52px;
            font-size: 28px;
        }

        &.third {
            .na {
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                line-height: 52px;
                text-align: center;
                -webkit-transition: all 0.5s ease;
                -moz-transition: all 0.5s ease;
                transition: all 0.5s ease;
            }

            .material-icons {
                -webkit-transition: all 0.5s ease;
                -moz-transition: all 0.5s ease;
                transition: all 0.5s ease;
            }

            &.n-a,
            &:nth-child(3) {
                .na {
                    opacity: 1;
                }

                .material-icons {
                    opacity: 0;
                }
            }
        }
    }

    &:hover,
    &:active {
        background: rgba($white, 0.12);
    }

    &:first-child {
        &.selected {
            border-color: $danger;
            color: $danger;
            background: none;

            &:hover {
                background: rgba($danger, 0.12);
            }
        }
    }

    &:nth-child(2) {
        margin-left: 40px;

        &.sm {
            margin-left: 1rem;
        }

        &.selected {
            border-color: $success;
            color: $success;
            background: none;

            &:hover {
                background: rgba($success, 0.12);
            }
        }
    }

    &:nth-child(3) {
        &.sm {
            margin-left: 1rem;
            font-weight: $fw-bold;
        }

        &.selected {
            border-color: $gray-dark;
            color: $gray-dark;
            background: none;

            &:hover {
                background: rgba($gray-dark, 0.12);
            }
        }
    }
}

.feedback-clicked {
    .feedback-circle {
        -webkit-transform: translateX(82px);
        -moz-transform: translateX(82px);
        transform: translateX(82px);

        &.good {
            -webkit-transform: translateX(82px) rotate(180deg);
            -moz-transform: translateX(82px) rotate(180deg);
            transform: translateX(82px) rotate(180deg);
        }

        &:nth-child(2) {
            -webkit-transform: translateX(-82px);
            -moz-transform: translateX(-82px);
            transform: translateX(-82px);

            &.good {
                -webkit-transform: translateX(-82px);
                -moz-transform: translateX(-82px);
                transform: translateX(-82px);
            }

            &.bad {
                -webkit-transform: translateX(-82px) rotate(180deg);
                -moz-transform: translateX(-82px) rotate(180deg);
                transform: translateX(-82px) rotate(180deg);
            }
        }

        &.sm {
            -webkit-transform: translateX(38px);
            -moz-transform: translateX(38px);
            transform: translateX(38px);

            &.good {
                -webkit-transform: translateX(38px) rotate(180deg);
                -moz-transform: translateX(38px) rotate(180deg);
                transform: translateX(38px) rotate(180deg);
            }

            &:nth-child(2) {
                -webkit-transform: translateX(-38px);
                -moz-transform: translateX(-38px);
                transform: translateX(-38px);

                &.good {
                    -webkit-transform: translateX(-38px);
                    -moz-transform: translateX(-38px);
                    transform: translateX(-38px);
                }

                &.bad {
                    -webkit-transform: translateX(-38px) rotate(180deg);
                    -moz-transform: translateX(-38px) rotate(180deg);
                    transform: translateX(-38px) rotate(180deg);
                }
            }

            &.third {
                -webkit-transform: translateX(74px);
                -moz-transform: translateX(74px);
                transform: translateX(74px);

                &.good {
                    -webkit-transform: translateX(74px) rotate(180deg);
                    -moz-transform: translateX(74px) rotate(180deg);
                    transform: translateX(74px) rotate(180deg);
                }

                &.n-a {
                    -webkit-transform: translateX(74px);
                    -moz-transform: translateX(74px);
                    transform: translateX(74px);
                }

                &:nth-child(2) {
                    transform: none;

                    &.good {
                        transform: none;
                    }

                    &.bad {
                        -webkit-transform: rotate(180deg);
                        -moz-transform: rotate(180deg);
                        transform: rotate(180deg);
                    }

                    .na {
                        opacity: 0;
                    }

                    &.n-a {
                        .na {
                            opacity: 1;
                        }

                        .material-icons {
                            opacity: 0;
                        }
                    }
                }

                &:nth-child(3) {
                    -webkit-transform: translateX(-74px);
                    -moz-transform: translateX(-74px);
                    transform: translateX(-74px);

                    &.good {
                        -webkit-transform: translateX(-74px);
                        -moz-transform: translateX(-74px);
                        transform: translateX(-74px);

                        .na {
                            opacity: 0;
                        }

                        .material-icons {
                            opacity: 1;
                        }
                    }

                    &.bad {
                        -webkit-transform: translateX(-74px) rotate(180deg);
                        -moz-transform: translateX(-74px) rotate(180deg);
                        transform: translateX(-74px) rotate(180deg);

                        .na {
                            opacity: 0;
                        }

                        .material-icons {
                            opacity: 1;
                        }
                    }

                    &.n-a {
                        .na {
                            opacity: 1;
                        }

                        .material-icons {
                            opacity: 0;
                        }
                    }
                }
            }
        }

        &:not(.selected) {
            opacity: 0;
        }
    }
}

.input-field textarea.bordered-textarea + label {
    text-align: left;
}

.align-center-buttons {
    .animated.fadeOutRight,
    .animated.fadeInRight {
        animation-duration: 0.3s;
    }
}
