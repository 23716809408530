.form-top-bar {
    background: rgba($gray-light, 0.2);
    border: 1px solid $gray-light;
    border-left: none;
    padding: 0 2rem 0 1.5rem;
    border-radius: 2px 2px 0 0;

    .flex {
        height: auto;
    }

    .flex-auto {
        padding: 1.313rem 0;
    }

    p {
        line-height: 2rem;
        margin-left: 1rem;
    }

    .switch {
        margin-left: 4.75rem;
    }

    .switch.simple label .lever:before {
        content: 'Simple';
    }

    .switch.simple label input[type='checkbox']:checked + .lever:before {
        content: 'Simple';
    }

    .switch.guide label .lever:before {
        content: 'Guide';
    }

    .switch.guide label input[type='checkbox']:checked + .lever:before {
        content: 'Guide';
    }

    .switch.simple label .lever:before,
    .switch.guide label .lever:before,
    .switch.simple label input[type='checkbox']:checked + .lever:before,
    .switch.guide label input[type='checkbox']:checked + .lever:before {
        font-size: 1rem;
        text-transform: none;
        width: 56px;
        left: -64px;
    }
}

.card.card-form {
    .input-field .dropdown-content {
        left: 0 !important;
        max-height: 400px;
    }
}

.card-form {
    box-shadow: none;

    &:hover {
        box-shadow: none;
    }

    .animated {
        &.row,
        &.guide-form {
            animation-duration: 0.5s;
        }
    }
}

.border-top {
    border-top: 1px solid $gray-light;
}

.border-bottom {
    border-bottom: 1px solid $gray-light;
}

.collapsible-body.content-body .select-wrapper ul {
    list-style: none;
    margin-left: 0;
}

.priority-select {
    li {
        list-style: none !important;

        .material-icons {
            line-height: 3rem;
            margin: 0 1rem;
        }

        .double-ar {
            position: relative;
            margin-top: 10px;
            display: inline-block;

            .material-icons.status-icon {
                line-height: 1;

                & + .status-icon {
                    position: absolute;
                    top: 5px;
                    left: 0;
                }
            }
        }
    }
}

.form {
    /*.input-field .select-wrapper {
		& + label {
			top: -11px;
		}
	}*/
    .input-field .helper-text {
        margin-bottom: 0;
        line-height: 30px;
    }

    .input-field.select-empty {
        .select-wrapper + label {
            top: 0.8rem;
            font-size: 1rem;

            &.active {
                top: -11px;
                font-size: 0.75rem;
                transform: none;
            }
        }
    }
}

.form-bottom-bar {
    padding: 0 2rem 0 1.5rem;
    background: rgba($gray-light, 0.2);
    border-radius: 0 0 2px 2px;

    .flex-auto {
        padding: 1rem 0;
    }

    .flex {
        height: auto;
    }

    .allert-info i {
        margin-right: 1rem;
        font-size: 1.5rem;
        line-height: 1;
        display: inline-block;
        vertical-align: -5px;
    }

    .btn-flat,
    .btn {
        .material-icons {
            font-size: 1.5rem;
            margin-right: 0.25rem;
            vertical-align: -6px;
            line-height: 1;
        }
    }

    button:last-child {
        margin-left: 0.5rem;
    }
}

.guide-form {
    margin: 62px auto;
    width: 420px;

    @media (max-width: 767px) {
        width: 320px;
    }

    .flex {
        height: auto;
    }

    h3 {
        margin-bottom: 1.5rem !important;
    }

    p.skip-hint {
        margin-bottom: 1rem;
    }

    .row {
        margin-bottom: 2rem;

        .input-field {
            text-align: left;
        }
    }

    .guide-btn-next {
        margin-top: 1.5rem;
    }

    .input-field textarea.bordered-textarea {
        margin-top: 1.5rem;
    }
}

.progress-page {
    z-index: 100;
}

.select-wrapper li.disabled {
    display: none;
}

.input-field .dropdown-content li.active > a,
.input-field .dropdown-content li.active > span {
    color: $primary;
}

input::-webkit-contacts-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0;
}

button.datepicker-filter-btn {
    margin: 0 0 0 -0.5rem !important;
    padding: 0 1rem !important;

    &.btn-neutral5 {
        &:hover {
            color: var(--neutral5);
            background-color: transparent;
        }
    }
}
