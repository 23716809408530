/* stylelint-disable */
/*header*/
header.navbar {
    nav {
        line-height: 56px;
        height: 56px;

        .nav-wrapper {
            padding: 0 1rem;

            @media #{$small-and-up} {
                padding: 0 2rem;
            }

            @media #{$medium-and-up} {
                padding: 0 3.5rem;
            }
        }

        .brand-logo {
            img {
                margin-top: 10px;
                height: 40px;
                width: auto;
            }

            @media (max-width: 960px) {
                left: 0;
                transform: none;
            }
        }

        ul {
            li {
                position: relative;
            }

            a,
            .navbar-logo {
                background: none;
                color: $gray-mid;
                height: 54px;

                &:hover,
                &.active {
                    color: $gray-mid;

                    i,
                    span {
                        color: $gray-mid;
                    }
                }

                &.active-page,
                &.open {
                    background-color: transparent;
                    color: rgba($white, 100%);

                    i,
                    span {
                        color: rgba($white, 100%);
                    }
                }

                &.active-page {
                    box-shadow: inset 0px -4px 0px 0px rgba(19, 147, 189, 1);
                }

                .icon.battery {
                    vertical-align: -7px;
                    margin-left: 0.5rem;
                    font-size: 30px;
                }

                i.material-icons {
                    vertical-align: 0;
                    line-height: 54px;

                    &.power {
                        line-height: inherit;
                    }
                }
            }

            .navbar-logo {
                &:hover {
                    background: none;
                }
            }
        }

        i,
        [class^='mdi-'],
        [class*='mdi-'],
        i.material-icons {
            display: inline-block;
            vertical-align: middle;

            &.battery {
                -ms-transform: rotate(90deg);
                -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
                margin-left: 5px;
            }
        }

        .dropdown-content {
            width: 300px !important;
            top: 100% !important;
            line-height: 1.5rem;
            left: auto !important;
            right: 0;

            &.block-content {
                padding: 1rem 1.5rem;

                ul {
                    margin: 0 -1.5rem -1rem;

                    li {
                        float: none;

                        a:not(.btn),
                        a:not(.btn):hover,
                        a:not(.btn):focus,
                        a:not(.btn):active {
                            padding: 8px 0.5rem 8px 1.5rem;

                            i {
                                line-height: 1.5rem;
                                margin-top: 3px;
                                margin-bottom: -3px;
                            }
                        }
                    }
                }
            }

            .icon-ethernet {
                font-size: 35px;
            }

            i {
                line-height: inherit;
                height: auto;
            }

            a:not(.btn) {
                line-height: 2rem;
                padding: 0;
                color: $link-color;
                height: auto;

                &:hover,
                &:active,
                &:focus {
                    color: $primary-hover;
                    height: auto;
                }

                .check {
                    display: none;
                }

                &.active {
                    color: $primary !important;

                    .check {
                        display: block;
                    }
                }
            }

            .list-styled {
                li {
                    min-height: 0;
                    height: auto;
                    float: none;

                    a:not(.btn),
                    a:hover:not(.btn),
                    a:active:not(.btn),
                    a:focus:not(.btn) {
                        color: $gray-dark;
                        padding: 8px 16px;
                    }

                    .icon {
                        vertical-align: -3px;
                        width: 24px;
                        height: 24px;
                        display: inline-block;

                        &:before {
                            color: $gray-mid;
                        }

                        .path1:before,
                        .path2:before,
                        .path3:before {
                            color: $gray-mid;
                        }
                    }
                }

                &.wifi-list {
                    li {
                        position: relative;

                        .material-icons.check {
                            position: absolute;
                            right: 1rem;
                            top: 50%;
                            margin-top: -1rem;
                            margin-right: 0;
                        }

                        &.active {
                            a {
                                color: $primary;

                                .material-icons.check {
                                    display: block;
                                }
                            }

                            .icon {
                                &:before {
                                    color: $primary;
                                }

                                .path1:before,
                                .path2:before,
                                .path3:before {
                                    color: $primary;
                                }
                            }
                        }
                    }
                }
            }
        }

        ul.dropdown-content {
            width: 200px !important;
            padding: 0;

            li {
                min-height: 0;
                height: auto;

                a:not(.btn),
                a:hover:not(.btn),
                a:active:not(.btn),
                a:focus:not(.btn) {
                    color: $gray-dark;
                    padding: 8px 16px;

                    i.power {
                        margin: 0;
                        font-size: 2rem;
                        transition: color 0.3s;
                    }
                }

                a:hover {
                    i.power {
                        color: $gray-mid !important;
                    }
                }
            }
        }
    }
}

.card.dashboard-card {
    padding: 1.5rem 1.5rem 1rem;
    border-left: 0.25rem solid transparent;
    margin: 0 0 $gutter-width-small;
    color: $gray-dark;

    @media #{$small-and-up} {
        margin-bottom: $gutter-width-tablet;
    }

    @media #{$medium-and-up} {
        margin-bottom: $gutter-width;
    }

    @media #{$xxlarge-and-up} {
        margin-bottom: $gutter-width-xxlarge;
    }

    &.border-dark-green {
        border-color: #006e54;
    }

    &.border-dark-blue {
        border-color: #074c8d;
    }

    &.border-green {
        border-color: $success;
    }

    &.border-primary {
        border-color: $primary;
    }

    &.border-gray-background {
        border-color: $gray-background;
    }

    &.border-gray-light {
        border-color: $gray-light;
    }

    &.border-gray-mid {
        border-color: $gray-mid;
    }

    &.border-gray-dark {
        border-color: $gray-dark;
    }

    &.border-success {
        border-color: $success;
    }

    &.border-warning {
        border-color: $warning;
    }

    &.border-danger {
        border-color: $danger;
    }

    &.border-pink {
        border-color: $pink;
    }

    &.border-cherry {
        border-color: $cherry;
    }

    &.border-purple {
        border-color: $purple;
    }

    &.border-deep-purple {
        border-color: $deep-purple;
    }

    &.border-indigo {
        border-color: $indigo;
    }

    &.border-light-blue {
        border-color: $light-blue;
    }

    &.border-teal {
        border-color: $teal;
    }

    &.border-lime {
        border-color: $lime;
    }

    &.border-yellow {
        border-color: $yellow;
    }

    &.border-orange {
        border-color: $orange;
    }

    &.border-blue-grey {
        border-color: $blue-grey;
    }

    &.border-white {
        border-color: $white;
    }

    h3 {
        margin-bottom: 0.5rem;
    }

    &.no-shadow {
        box-shadow: none;
        border: 1px solid $gray-light;
    }

    .chip {
        padding: 0;
        width: 40px;
        height: 40px;
        border-radius: 1000px;
        margin-right: 1rem;

        img {
            margin: 0;
            width: 40px;
            height: 40px;
        }

        .dot {
            right: 0;
            left: auto;
            box-sizing: border-box;
            width: 14px;
            height: 14px;
        }
    }

    .icon {
        font-size: 80px;
        margin-bottom: 0.5rem;
    }

    .image {
        height: 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;

        &.sm {
            height: 56px;
        }

        img {
            max-height: 100%;
            max-width: 100%;
        }
    }

    .text-box {
        float: left;
        width: calc(100% - 60px);

        h4 {
            line-height: 1;
        }

        .rate {
            .material-icons {
                font-size: $fz-small;
                vertical-align: -2px;
            }
        }

        @media (max-width: 800px) {
            .right {
                width: 100%;
            }
        }
    }

    &.expanded {
        position: relative;
        box-shadow: none;
        border: 1px solid $gray-light;
        padding: 2rem 2rem 1rem;
        line-height: 1.5rem;

        .close-button {
            position: absolute;
            right: 0.5rem;
            top: 0.5rem;

            .material-icons {
                color: $gray-mid;
                font-size: 1.5rem;
            }
        }

        .image {
            width: 200px;
            height: 200px;
            position: relative;
            overflow: hidden;
            padding: 0;

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                min-width: 100%;
                min-height: 100%;
                max-height: 200%;
                max-width: 200%;
            }

            @media (max-width: 767px) {
                &.left {
                    float: none !important;
                }
            }
        }

        .text-box {
            width: calc(100% - 240px);

            @media (max-width: 767px) {
                width: 100%;
            }

            @media (max-width: 1280px) {
                .right {
                    text-align: left !important;
                    width: 100%;
                    margin-bottom: 0.5rem;
                }
            }

            .dot {
                width: 12px;
                height: 12px;
            }

            .indicator {
                font-size: $fz-small;
            }

            .rate {
                margin-right: 1rem;
                display: inline-block;
                margin-left: 1rem;

                .material-icons {
                    font-size: 1rem;
                    color: $orange;
                }
            }

            .caption {
                font-size: $fz-small;
            }

            .quote {
                padding-left: 2rem;
                position: relative;
                margin-top: 1rem;

                .material-icons {
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }
    }

    .text-box {
        float: left;
        width: calc(100% - 60px);

        h4 {
            line-height: 1;
        }

        .rate {
            .material-icons {
                font-size: $fz-small;
                vertical-align: -2px;
            }
        }
    }
}

/*Dashboard*/
.dashboard-wrapper > .flex {
    height: 100vh;
    position: relative;
}

.grid.masonry-three {
    .tile {
        width: calc(100% / 3 - 2rem);
        margin: 0 0 2rem;
    }
}

/*Modals*/
.modal {
    top: 50% !important;
    transform: scaleX(1) translateY(-50%) !important;

    &.modal-xsmall {
        width: 280px;
    }

    .modal-content {
        p {
            font-size: $fz-normal;
        }
    }

    .modal-footer {
        .btn,
        .btn-flat {
            margin-left: 0.5rem;
        }
    }
}

.progress-page {
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .progress {
        width: 600px;
        margin: 0 auto 50px;
    }
}

@media (max-width: 767px) {
    .navbar {
        margin-bottom: 1rem;

        .right {
            & > li {
                display: none;

                &.show-on-small {
                    display: block !important;
                }
            }
        }
    }
}

.card.waiting-card {
    height: 350px;
    padding: 60px;
    font-size: 24px;

    p {
        margin: 0;
    }

    .material-icons {
        font-size: 48px;
    }

    .status {
        font-size: 18px;
    }

    .timer {
        font-size: 36px;
    }
}

.overflow-auto {
    overflow: auto;
}

.wifi-icon {
    .icon {
        display: inline-block;
        width: 24px;

        .path1,
        .path2,
        .path3 {
            &:before {
                color: $white;
            }
        }
    }
}

footer.sticky {
    background: rgba($white, 1);
    box-shadow: 0 0 6px rgba($black, 0.16);
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 3;

    .btn-large.square-mobile {
        @media (max-width: 767px) {
            min-width: 0;
            width: 50px;
            text-align: center;
            padding-right: 0;
            padding-left: 0;

            .material-icons {
                margin: 0;
            }
        }
    }

    &.disabled {
        opacity: 0.5;
    }
}

.dash-scroll {
    overflow: auto;
    padding-bottom: 85px;
}

.dash-box {
    &.mh {
        min-height: 400px;
        position: relative;
        padding-bottom: 88px;

        @media #{$small-only} {
            padding-bottom: 168px;
        }
    }

    .search-form .input-field input {
        margin: 0;
    }

    .select-wrapper input.select-dropdown {
        margin: 0;
    }

    @media #{$medium-and-down} {
        .search-form .input-field input {
            margin-bottom: 1rem;
        }

        .select-wrapper input.select-dropdown {
            margin-bottom: 1rem;
        }
    }

    @media #{$small-and-up} {
        padding-left: $gutter-width-tablet;
        padding-right: $gutter-width-tablet;
    }

    @media #{$medium-and-up} {
        padding-left: $gutter-width;
        padding-right: $gutter-width;
    }

    @media #{$xxlarge-and-up} {
        padding-left: $gutter-width-xxlarge;
        padding-right: $gutter-width-xxlarge;
    }

    &.border-pink {
        border-left: 4px solid $pink;
    }

    .search-form .input-field input {
        margin-bottom: 0;
    }

    .grid-list-item {
        border: none;
        padding: ($gutter-width-small / 2);

        @media #{$small-and-up} {
            padding: ($gutter-width-tablet / 2);
        }

        @media #{$medium-and-up} {
            padding: ($gutter-width / 2);
        }

        @media #{$xxlarge-and-up} {
            padding: ($gutter-width-xxlarge / 2);
        }

        .card.dashboard-card {
            margin: 0;
        }

        &.fullwidth {
            width: 100%;
        }
    }

    .js-card-expanded {
        transition: 0.3s opacity linear;
        position: absolute;
        top: 0;
        left: 0;

        &.hide {
            display: block !important;
            opacity: 0;
            visibility: hidden;
        }
    }
}

.empty-stepper {
    ul.stepper.horizontal .step-title {
        padding-right: 28px;
    }

    ul.stepper.horizontal .step:not(:last-of-type):after {
        margin-top: -3px;
    }
}

.block {
    display: block !important;
}

avz-indicator-component .dot {
    border: none;
    vertical-align: -1px;
}

.btn.btn-simple.on-gray {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.12), inset 0 -3px 0 rgba(0, 0, 0, 0.12) !important;
}

.avatar.s220 {
    width: 220px;
    height: 220px;
    position: relative;
    line-height: 220px;
    font-size: 3rem;

    .circle {
        width: 212px;
        height: 212px;
    }

    img.circle {
        width: auto;
        height: auto;
        max-width: 200%;
        max-height: 200%;
        min-width: 100%;
        min-height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
    }

    .bordered {
        border: 0.5rem solid $white;
        border-radius: 1000px;
        position: absolute;
        top: 0;
        left: 0;
        content: '';
        width: 100%;
        height: 100%;
    }

    &.bordered.border-3 {
        border-width: 3px;

        &.border-progress-color {
            border-color: #edc41a;
        }
    }

    .icon {
        line-height: 210px;
    }
}

.over {
    margin-top: 160px;
    padding-bottom: 90px;

    .avatar.s220 {
        position: relative;
        margin-top: -134px;
        margin-bottom: 1rem;
        display: inline-block;

        .img-placeholder {
            font-size: 140px;
            line-height: 190px;
            width: 212px;
            height: 212px;
            border: 0.5rem solid $white;
            border-radius: 1000px;
        }
    }
}

.waiting-bottom {
    font-size: 2rem;
    border-radius: 0 0 4px 4px;
    padding: 1.5rem;
    line-height: 2.5rem;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.progress-color {
    background: #edc41a;
}

.material-icons.reassign {
    font-size: 80px;
    vertical-align: top;
    margin: 0 40px;
}

.empty-stepper {
    .stepper {
        .step:last-child {
            .step-title {
                padding-left: 0;
            }
        }
    }
}

.btn-large.btn-fullwidth.p0 {
    width: 100%;
}

.avatar.medium {
    position: relative;

    .circle.medium {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 100%;
        min-height: 100%;
        max-height: 200%;
        max-width: 200%;
        width: auto;
        height: auto;
    }
}

.modal {
    color: $gray-dark;
}

.module-title {
    display: inline-block;
    height: 2.875rem;
    line-height: 2.875rem;
    margin-left: 3rem;

    .icon {
        display: inline-block;
        font-size: 3.75rem;
        margin-right: 1rem;
        vertical-align: middle;
    }
}

.dash-height {
    height: calc(100vh - 70px);
}

.cc-button {
    .avatar.medium {
        color: $white;
        line-height: 96px;
    }
}

.avatar-caller {
    .user-initials {
        width: 244px;
        line-height: 244px;
        text-align: center;
        font-size: 3rem;
        display: block;
        border-radius: 1000px;
        position: relative;
        top: -2px;
        left: -2px;
        z-index: 2;
    }
}

aw-input .input-field.has-label label,
aw-input-phone .input-field.has-label label {
    top: 0 !important;
}

.icon-type-not-set-black::before {
    content: '\eaa5';
}
