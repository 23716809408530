.noty_layout {
    left: auto !important;
    bottom: auto !important;
    right: 1.5rem !important;
    top: 1.5rem !important;
    transform: translateZ(0) scale(1) !important;
    width: 90% !important;
    max-width: 504px !important;
    display: flex !important;
    flex-direction: column-reverse !important;
    align-items: flex-end !important;
    z-index: 1001 !important;

    .flash-dialog {
        &.primary {
            background-color: var(--primary);
        }

        /* Temporal fix for ADS components 2.3.34 */
        .wrapper__actions {
            .icon-link.js-close {
                pointer-events: none !important;
            }
        }
    }

    @media (min-width: 480px) {
        .noty_bar .flash-dialog {
            min-width: 370px !important;
        }
    }
}
