.card.login-card {
    width: 600px;
    margin: auto;
    padding: 30px;

    .logo {
        display: block;
        margin: 0 auto 2rem;
        width: 185px;
    }

    h1 {
        text-align: center;
    }

    .link-block {
        position: relative;
        display: block;
        border-radius: 4px;
        transition: box-shadow 0.25s;
        @extend .z-depth-2;

        &:hover {
            @extend .z-depth-8;
        }

        img {
            width: 100%;
            display: block;
        }

        .text-box {
            color: $white;
            position: absolute;
            padding: 1.5rem 1rem;
            bottom: 0;
            left: 0;
            width: 100%;
            background: rgba(0, 0, 0, 0.5);
            /* Old browsers */
            background: -moz-linear-gradient(
                top,
                rgba(0, 0, 0, 0.2) 0%,
                rgba(0, 0, 0, 0.4) 60%,
                rgba(0, 0, 0, 0.5) 100%
            );
            /* FF3.6-15 */
            background: -webkit-linear-gradient(
                top,
                rgba(0, 0, 0, 0.2) 0%,
                rgba(0, 0, 0, 0.4) 60%,
                rgba(0, 0, 0, 0.5) 100%
            );
            /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(
                to bottom,
                rgba(0, 0, 0, 0.2) 0%,
                rgba(0, 0, 0, 0.4) 60%,
                rgba(0, 0, 0, 0.5) 100%
            );

            /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            .h3 {
                font-size: $fz-h3;
                display: block;
                margin-bottom: 0.5rem;
            }
        }
    }

    .card-actions {
        padding-top: 15px;

        .btn,
        .btn-flat {
            margin-left: 0.5rem;

            &.left {
                margin: 0;
            }
        }
    }
}

.row {
    margin: 0 (-1 * $gutter-width-small / 2);

    @media #{$small-and-up} {
        margin-left: (-1 * $gutter-width-tablet / 2);
        margin-right: (-1 * $gutter-width-tablet / 2);
    }

    @media #{$medium-and-up} {
        margin-left: (-1 * $gutter-width / 2);
        margin-right: (-1 * $gutter-width / 2);
    }

    @media #{$xxlarge-and-up} {
        margin-left: (-1 * $gutter-width-xxlarge / 2);
        margin-right: (-1 * $gutter-width-xxlarge / 2);
    }
}

.logo-white {
    width: 185px;
    margin: auto;
}

.btn-flat.btn-large.btn-secondary,
.btn-flat.btn-large.btn-danger {
    border: none;
    box-shadow: none !important;
}

.btn-flat.btn-large.btn-danger:not(:hover) {
    background: none;
}

.select-wrapper {
    .dropdown-content {
        &.wifi-select {
            .material-icons {
                color: $black;
                line-height: 48px;
                margin-right: 1rem;
            }
        }
    }
}

.pass-field {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease;

    &.active {
        max-height: 200px;
        overflow: visible;
    }
}

.transition-off {
    transition: none !important;
}

.primary.absolute {
    position: absolute;
    top: 0;
    left: 0;

    &.fadeOut {
        z-index: 0;
    }

    &.fadeIn {
        z-index: 1;
    }
}

.login-nav {
    position: absolute;
    top: 0;
    right: 70px;
    width: 300px;

    .menu {
        color: $white;
        height: 56px;
        line-height: 56px;
        padding: 0 1rem;

        i {
            height: auto;
            line-height: inherit;
        }

        &:hover,
        &:focus {
            color: $white;
        }
    }

    ul.dropdown-content {
        width: 200px !important;
        padding: 0;
        top: 100% !important;
        left: auto !important;
        right: 0;

        a:not(.btn) {
            line-height: 2rem;
            padding: 0;
            height: auto;
        }

        li {
            min-height: 0;
            height: auto;

            a:not(.btn),
            a:hover:not(.btn),
            a:active:not(.btn),
            a:focus:not(.btn) {
                color: $gray-dark;
                padding: 8px 16px;

                i.power {
                    margin: 0;
                    font-size: 2rem;
                    transition: color 0.3s;
                }
            }

            a:hover {
                i.power {
                    color: $gray-mid !important;
                }
            }
        }
    }
}

.welcome-bottom {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.7s linear;

    &.active {
        max-height: 200px;
    }

    .animated {
        opacity: 0;

        &.zoomIn {
            opacity: 1;
        }
    }
}

.centered {
    width: 800px;
    margin: 0 auto;

    @media #{$small-only} {
        max-width: 100%;
    }
}

.icon.logins {
    font-size: 160px;
    margin-bottom: 1rem;
    display: block;
    width: 160px;
    height: 160px;
}

.icon.custom-logins {
    font-size: 160px;
    margin-bottom: 1rem;
    display: block;
    height: 160px;
}

.btn.w260 {
    width: 260px;
}

.login-white {
    background: $white;
    border-radius: 3px;
    box-shadow: 0 0 4px rgba($black, 0.16);
    width: 80%;
    padding-top: 66rem;

    .empty-dash-screen & {
        border-radius: 10px;
        width: unset;
        max-width: 80%;
    }
}

.icon-qr-code-activation-failure .path3:before {
    color: $white;
}
