aw-button {
    &.width-100 {
        button {
            width: 100%;
        }
    }

    &.flat-danger {
        button {
            border: 1px solid var(--danger);
            border-radius: 25px;
            color: var(--danger);
        }
    }

    &.border-25 {
        button {
            border-radius: 25px;
        }
    }

    &.border-primary {
        button {
            border: 1px solid var(--primary);
        }
    }

    &.border-danger {
        button {
            border: 1px solid var(--danger);
        }
    }

    &.height-48 {
        button {
            height: 48px;
        }
    }

    &.font-size-20 {
        button {
            font-size: 20px;
        }
    }
}
