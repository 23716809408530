html,
body {
    height: 100%;
}

body {
    overflow: hidden;
}

.keyboard {
    z-index: 10000;
    position: absolute;
    top: unset;
    bottom: 0;
    width: 100%;
}
