.clearfix {
    &:after {
        clear: both;
        display: block;
        content: '';
    }
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-uppercase {
    text-transform: uppercase !important;
}

strong {
    font-weight: $fw-bold;
}

.inline-flex {
    display: inline-flex;
}

.inline-block {
    display: inline-block;
}

.hidden {
    visibility: hidden;
    pointer-events: none;
    cursor: default;
}

.layout-flex {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    overflow: visible;
    min-height: 100%;
    width: 100%;
}

.cursor-default {
    cursor: default;
}

.cursor-pointer {
    cursor: pointer;
}

.rel {
    position: relative;
}

@mixin aspect-ratio($width, $height) {
    position: relative;

    &:before {
        display: block;
        content: '';
        width: 100%;
        padding-top: ($height / $width) * 100%;
    }

    & > .content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
    }
}

.sixteen-nine {
    @include aspect-ratio(16, 9);
}

.one-one {
    @include aspect-ratio(1, 1);
}

.pointer {
    cursor: pointer;
}

/*chip-jumbo*/

.chip-jumbo {
    position: relative;
    background: transparent;
    color: $white;
    cursor: pointer;
    border: 2px solid $white;
    border-radius: 32px;
    display: inline-block;
    font-weight: $fw-normal;
    height: 3rem;
    font-size: $fz-normal;
    letter-spacing: 0.01em;
    padding: 0 2rem;
    outline: none;
    vertical-align: top;
    line-height: 1;
    transition: all 0.24s cubic-bezier(0.4, 0, 0.2, 1);
    will-change: padding;
    white-space: nowrap;
    user-select: none;
    overflow: hidden;
    margin-left: 10px;
    margin-right: 10px;

    .left {
        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: flex-start;
        justify-content: center;
    }

    i {
        color: $success;
        position: absolute;
        font-size: 2rem;
        line-height: 2.75rem;
        margin-left: 1rem;
        transition: all 0.24s cubic-bezier(0.4, 0, 0.2, 1);
        opacity: 0;
        transform: translateX(1rem);
        will-change: opacity, transform;
    }

    span {
        font-size: $fz-small;
        line-height: 1.125rem;
        color: $white;
        display: block;
        letter-spacing: 0.01em;
    }

    &:after {
        clear: both;
        display: block;
        content: '';
    }

    &.active {
        background-color: $white;
        color: $gray-dark;
        padding-right: 3.5rem;

        i {
            opacity: 1;
            transform: translateX(0);
        }

        span {
            color: $gray-mid;
        }
    }
}

.chip-jumbo-flat {
    position: relative;
    background: transparent !important;
    color: $white;
    cursor: pointer;
    border: 2px solid $white;
    border-radius: 32px;
    display: inline-block;
    font-weight: $fw-normal;
    height: 3rem;
    font-size: $fz-normal;
    letter-spacing: 0.01em;
    padding: 0 2rem;
    outline: none;
    vertical-align: top;
    line-height: 1;
    white-space: nowrap;
    user-select: none;
    overflow: hidden;
    will-change: padding;

    .chip-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: flex-center;
        justify-content: center;

        &.layout-row {
            display: flex;
            -webkit-box-orient: horisontal;
            -webkit-flex-direction: row;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;

            .icon,
            .material-icons {
                margin-right: 0.5rem;
            }
        }
    }

    &:after {
        clear: both;
        display: block;
        content: '';
    }
}

/*jumbo buttons*/

.icon-link-jumbo {
    display: inline-block;

    .material-icons {
        border-radius: 1000px;
        text-align: center;
        font-size: 24px;
        width: 48px;
        height: 48px;
        line-height: 48px;
    }

    &:hover,
    &:focus,
    &:active {
        .material-icons {
            background: rgba(0, 0, 0, 0.12);
        }

        &.pink-text {
            .material-icons {
                background: rgba(233, 30, 99, 0.26);
            }
        }

        &.white-text {
            .material-icons {
                background: rgba($white, 0.3);
            }
        }
    }

    &[disabled] {
        cursor: default;
        color: rgba(117, 117, 117, 0.38) !important;

        &:hover,
        &:focus,
        &:active {
            .material-icons {
                background: none;
            }
        }
    }
}

.btn-jumbo {
    border: $button-border;
    display: inline-block;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
    width: 140px;
    height: 140px;
    font-size: $fz-normal;
    padding: 0;
    border-radius: 8px;
    text-transform: none;
    letter-spacing: 0;
    box-shadow: inset 0 -7px 0 rgba(0, 0, 0, 0.12), 0 0 4px rgba(0, 0, 0, 0.14),
        0 1px 5px rgba(0, 0, 0, 0.2) !important;
    outline: 0;
    transition: all 0.5s linear 0s, background 0.3s linear 0s;

    i {
        line-height: 46px !important;
        display: block;
        line-height: inherit;
    }

    h3 {
        font-size: $fz-normal;
        font-weight: $fw-normal;
    }
}

.btn-jumbo.disabled,
.btn-jumbo:disabled,
.btn-jumbo[disabled] {
    pointer-events: none;
    background-color: $button-disabled-background !important;
    box-shadow: none;
    color: $button-disabled-color !important;
    cursor: default;

    &:hover {
        background-color: $button-disabled-background !important;
        color: $button-disabled-color !important;
    }

    .bg-dark & {
        color: rgba($white, 0.33) !important;
        background-color: rgba($white, 0.12) !important;

        & > * {
            color: #9f9f9f !important;
        }
    }
}

.btn-jumbo {
    &:focus {
        background-color: darken($button-raised-background, 10%);
    }
}

/* range slider */
.slider-jumbo {
    margin-bottom: 0;
    height: 42px;
    padding: 0 10px;

    .noUi-horizontal .noUi-handle {
        width: 32px;
        height: 32px;
        left: -16px;
        top: -13px;
        cursor: pointer;
        touch-action: none;
    }

    .noUi-base {
        height: 6px;
        top: 0px;
        cursor: pointer;
        background: #5b6b7b;
        border-radius: var(--slider-line-border-radius);
    }

    .noUi-background {
        height: 6px;
        top: 18px;
    }

    .noUi-connect {
        height: 6px;
        top: 0;
    }

    .noUi-target {
        border-radius: 0;
        margin-top: 0;

        .noUi-origin {
            border-radius: 0;
            top: 0px;
            height: 6px;
            background: rgba(0, 0, 0, 0.3);
        }
    }

    .noUi-target.noUi-connect {
        box-shadow: none;
    }

    .noUi-horizontal .noUi-handle.noUi-active:after {
        position: absolute;
        content: '';
        width: 42px;
        height: 42px;
        background: $white;
        opacity: 0.26;
    }

    .noUi-on-start.noUi-horizontal .noUi-handle {
        border: none;
    }

    &.slider-white {
        .noUi-horizontal .noUi-handle {
            background: #1774cc;
        }

        .noUi-background {
            background: rgba(0, 0, 0, 0.3);
        }

        .noUi-connect {
            background: #46aff2;
        }

        .noUi-target {
            background: $white;
        }
    }

    .noUi-connects {
        height: 6px;
        top: 0;
    }
}

.zoom-panel {
    .noUi-base {
        width: calc(100% + 17px);
    }
}

.slider-jumbo.slider-white .noUi-target {
    background: transparent !important;
}

.flash-dialog.success {
    &.record-notify {
        .icon-link {
            width: 48px;
            height: 48px;

            i {
                font-size: 48px;
                height: 48px;
                width: 48px;
                line-height: 48px;
            }
        }
    }
}

/* video call */
.video-wrapper {
    position: relative;
    height: 100%;
    overflow: hidden;
    background: $black;
    line-height: 1.5rem;

    .video-inner {
        width: 100%;
        position: relative;
        top: 30px;
        left: 0;
        flex: 1 1 auto;
        -webkit-transition: height 1s cubic-bezier(0.4, 0, 0.2, 1);
        transition: height 1s cubic-bezier(0.4, 0, 0.2, 1);
        will-change: height;

        &.full-video-inner {
            top: 0px;
        }
    }
}

/* self-view */
.companion {
    position: absolute;
    top: 18px;
    left: 10px;
    width: 30%;
    max-width: 260px;
    max-height: 186px;

    @media (max-width: 1600px) {
        width: 24%;
    }

    z-index: 2;

    .preview-container {
        border: 1px solid rgba($gray-mid, 0.2);
        box-sizing: border-box;

        video,
        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }
}

/* top-shadow */
.scrim-top {
    background: -moz-linear-gradient(
        top,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0.1) 60%,
        rgba(0, 0, 0, 0) 100%
    );
    background: -webkit-linear-gradient(
        top,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0.1) 60%,
        rgba(0, 0, 0, 0) 100%
    );
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0.1) 60%,
        rgba(0, 0, 0, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#66000000', endColorstr='#00000000', GradientType=0);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
    height: 120px;
    z-index: 2;

    &.slideoff {
        top: -200px;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }
}

/* topnav */
.video-topnav {
    padding: 30px 20px 0;
    align-items: flex-start;
    color: $white;
    height: 120px;
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;

    .topnav-left {
        min-width: 50px;

        @media (min-width: 960px) {
            min-width: 110px;
        }

        span {
            display: inline-block;
            font-size: 1.5rem;
            line-height: 3rem;
        }
    }

    .topnav-middle {
        margin: 0 auto;
        padding-right: 112px;

        h3 {
            font-weight: $fw-bold;

            span {
                display: inline-block;
            }
        }
    }

    .topnav-right {
        position: absolute;
        right: 30px;
        top: -8px;

        @media (min-width: 960px) {
            min-width: 110px;
        }

        h4 {
            display: inline-block;
            font-weight: $fw-normal;
            font-size: 1rem;
            line-height: 3rem;
            margin-right: 2.5rem;
        }

        .bat-icon {
            -webkit-transform: rotate(90deg);
            -moz-transform: rotate(90deg);
            -o-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
            transform: rotate(90deg);
            position: absolute;
            font-size: 1.5rem;
            line-height: 3rem;
            top: 0;
            right: 0.5rem;
        }
    }
}

/* bottomnav */
.video-bottomnav {
    height: 60px;
    justify-content: center;
    z-index: 2;
    background: -moz-linear-gradient(
        bottom,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0.1) 60%,
        rgba(0, 0, 0, 0) 100%
    );
    background: -webkit-linear-gradient(
        bottom,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0.1) 60%,
        rgba(0, 0, 0, 0) 100%
    );
    background: linear-gradient(
        to top,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0.1) 60%,
        rgba(0, 0, 0, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#66000000', endColorstr='#00000000', GradientType=0);
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    transition: all 0.5s ease;

    .button-wrap {
        margin-top: -88px;
        text-align: center;

        & + .button-wrap {
            margin-left: 1.25rem;
        }

        h4 {
            font-weight: $fw-normal;
            user-select: none;
            font-size: 1rem;
            line-height: 1.5rem;
            margin-top: 0.5rem;
        }
    }

    &.menu-open {
        bottom: 42px;

        .button-wrap {
            margin-top: -50px;
        }
    }

    &.slideoff {
        bottom: -200px;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        transition: all 0.5s ease;
    }

    .menu-button {
        -webkit-transform: translateX(0px);
        -moz-transform: translateX(0px);
        transform: translateX(0px);
        -webkit-transition: transform 0.5s ease;
        -moz-transition: transform 0.5s ease;
        transition: transform 0.5s ease;
        z-index: 1;
        outline: none !important;
    }

    &.menu-open {
        .menu-button {
            -webkit-transform: translateX(42px);
            -moz-transform: translateX(42px);
            transform: translateX(42px);
            -webkit-transition: transform 0.5s ease;
            -moz-transition: transform 0.5s ease;
            transition: transform 0.5s ease;
        }
    }
}

.fadeOutLeft,
.fadeInLeft {
    animation-duration: 0.3s;
}

.button-wrap.end-button.animated.fadeOutLeft {
    z-index: 0;
}

.menu-activate {
    .material-icons {
        position: absolute;
        top: 0;
        left: 0;
    }

    .onstart {
        opacity: 1;
        transform: scale(1) rotate(0);
        transition: transform linear 0.3s, opacity linear 0.2s,
            -webkit-transform linear 0.3s;
    }

    .onview {
        opacity: 0;
        transform: rotate(-60deg);
        transition: transform linear 0.3s, opacity linear 0.2s,
            -webkit-transform linear 0.3s;
    }

    &.active {
        .onstart {
            opacity: 0;
            transform: scale(0) rotate(60deg);
        }

        .onview {
            opacity: 1;
            transform: rotate(0deg);
        }

        & + h4 {
            span {
                display: inline-block;
            }
        }
    }

    & + h4 {
        span {
            display: none;
        }
    }
}

/* main menu */
.menu-main {
    width: 100%;
    font-size: 0;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    position: relative;
    z-index: 3;
    background: #000;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex: 0 0 auto;
    flex: 0 1 auto;
    -webkit-transition: margin 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    transition: margin 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    will-change: margin;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    padding: 0.3125rem 0;
    margin-bottom: -167px;
    letter-spacing: 0;

    &.active {
        flex: 0 1 auto;
        margin-bottom: 0;
    }

    button,
    .menu-panel {
        margin-left: 0.25rem;
        letter-spacing: 0.01em;
    }

    & > * > *:last-child {
        margin-right: 0.25rem;
    }

    & > *,
    & > * > *,
    & > * > * > * {
        display: inline-block;
        flex: 1 0 auto;
    }

    .menu-panel {
        height: 156px;
        padding: 0 26px;
        font-size: $fz-normal;
        border: none;
        display: inline-block;
        text-align: center;
        vertical-align: middle;
        -webkit-tap-highlight-color: transparent;
        border-radius: 8px;
        text-transform: none;
        box-shadow: none;
        outline: 0;
        transition: all 0.5s linear 0s;
        will-change: all;

        .mp-body {
            height: 100%;
        }

        .panel-top {
            padding-top: 1.5rem;
            padding-bottom: 1.25rem;
            font-size: 0;

            & > * {
                margin-right: 0.5rem;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}

/* mic-btn change icon */
.mic-btn {
    .mic-on-ic,
    .mic-on-text {
        display: none;
    }

    &.mic-off {
        .mic-on-ic,
        .mic-on-text {
            display: block;
        }

        .mic-off-ic,
        .mic-off-text {
            display: none;
        }
    }
}

/* speakers */
/* speaker-btn change icon */
.speaker-btn {
    .speaker-on-ic,
    .speaker-on-text {
        display: none;
    }

    &.speaker-off {
        .speaker-on-ic,
        .speaker-on-text {
            display: block;
        }

        .speaker-off-ic,
        .speaker-off-text {
            display: none;
        }
    }
}

/* camera */

.camera-btn {
    .camera-on-ic,
    .camera-on-text {
        display: none;
    }

    &.camera-off {
        .camera-on-ic,
        .camera-on-text {
            display: block;
        }

        .camera-off-ic,
        .camera-off-text {
            display: none;
        }
    }
}

.enable-cam-btn {
    margin-top: 0;
}

.s-prev,
.s-next {
    margin-top: 26px;
    transition: margin-top 0.3s linear 0s;
    will-change: margin-top;
}

/* call blue screens*/

.wrapper-caller-info {
    height: 100%;
    width: 100%;
    overflow: hidden;
    background-size: cover;
    background-position: 50% 50%;
    background-color: #232a30;
    background-image: url('../../../assets/images/incoming-bg.webp') !important;
}

.caller-info {
    min-height: 360px;

    .avatar-caller {
        width: 240px;
        height: 240px;

        img {
            position: absolute;
            z-index: 2;
            width: inherit;
            height: inherit;
            transform: scale(1.01);
        }
    }
}

.call-buttons {
    width: 250px;
    margin-top: 4rem;

    .button-wrap {
        text-align: center;
        outline: none !important;

        h4 {
            font-size: $fz-normal;
            font-weight: 400;
            margin-top: 0.5rem;
            text-align: center;
        }
    }

    [class^='btn-'].danger:hover {
        background-color: shade($danger, 12%) !important;
    }

    [class^='btn-'].success:hover {
        background-color: shade($success, 12%) !important;
    }

    [class^='btn-'].white:hover {
        background-color: shade($white, 12%) !important;
    }

    .btn-floating.btn-success:active {
        background-color: #007a06 !important;
        cursor: default !important;
        -webkit-tap-highlight-color: transparent;
    }

    .btn-floating.btn-danger:active {
        background-color: #c01316 !important;
        cursor: default !important;
        -webkit-tap-highlight-color: transparent;
    }

    .btn-floating:focus {
        &:before {
            display: none !important;
        }
    }
}

#reject i,
#cancel i {
    transform: rotate(135deg);
}

//Pulse Animation
@keyframes pulse-animation {
    0% {
        opacity: 1;
        transform: scale(1);
    }

    //Delay between pulses
    20% {
        opacity: 1;
        transform: scale(1);
    }

    80% {
        opacity: 0;
    }

    100% {
        opacity: 0;
        transform: scale(1.5);
    }
}

.ripple-wave {
    &::before,
    &::after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: white !important;
        will-change: transform, opacity;
    }

    &::after {
        animation: pulse-animation 2s cubic-bezier(0.24, 0, 0.38, 1) infinite;
        transition: opacity 0.4s, transform 0.4s;
    }

    position: absolute;
    border-radius: 50%;
    z-index: 1;
    height: 240px !important;
    width: 240px !important;

    .ripple-wave-2 {
        &::before {
            content: '';
            display: block;
            height: inherit;
            width: inherit;
            border-radius: 50%;
            background-color: white !important;
            animation: pulse-animation 2s cubic-bezier(0.5, 0, 0.6, 1) infinite;
            animation-delay: 0.24s;
        }

        position: absolute;
        height: inherit;
        width: inherit;
    }
}

.call-fail {
    .ripple-wave {
        display: none;
    }
}

/* advanced settings */

.call-log {
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);
    padding: 4.688rem 0 3rem;

    @media (max-width: 480px) {
        padding: 3rem 0 1.5rem;
    }

    .call-log-column {
        h4 {
            font-size: $fz-h4;
        }

        .row .col {
            font-size: $fz-normal;
            line-height: 1.5rem;

            &:first-child {
                font-weight: $fw-bold;
            }
        }
    }
}

.blur {
    -webkit-transition: filter 0.3s ease-in-out;
    transition: filter 0.3s ease-in-out;
    -webkit-filter: blur(4px);
    filter: blur(4px);
    height: 100%;
    width: 100%;
}

.log-title {
    fieldset {
        border: none;
        margin: 0;
        border-top: 1px solid $white;
        padding: 0;

        legend {
            border: 0;
            padding: 0 2rem 0 0;

            h1 {
                margin-bottom: 2rem;
            }
        }
    }
}

/* chip text - to icon*/
.chip-jumbo-flat {
    &.help-chip {
        padding: 0 2rem;

        &.active {
            padding: 0 0.63rem;
            z-index: 3;
        }

        .material-icons {
            margin-right: 0 !important;
        }
    }

    .chip-icon-close .material-icons {
        font-size: 1rem;
        line-height: 2rem;
    }
}

.m-t12 {
    margin-top: 0;
    vertical-align: top;
}

/* devices */

.menu-main .menu-panel {
    &.device-panel {
        padding-left: 0;
        text-align: left;
    }
}

.device-img {
    width: 140px;
    height: 140px;
    margin-right: 30px;
    transition: all 0.5s linear 0s;

    img {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 8px 0 0 8px;
    }
}

.rec-icon {
    font-size: 24px;
    width: 24px;
    height: 24px;
    line-height: 24px;
    padding: 2px;
    display: inline-block;

    &:before {
        display: block;
        content: '';
        width: 20px;
        height: 20px;
        background: $white;
        border-radius: 50%;
        transition: all 0.3s linear;
        vertical-align: top;
    }

    &.stop-icon:before {
        border-radius: 0;
    }
}

.dev-title {
    position: absolute;
    top: auto;
    left: 0;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    line-height: normal;

    .help-open & {
        width: auto;
    }
}

.panel-textarea-wrap {
    padding: 1rem;
    width: 400px;
    height: 100%;

    .input-field {
        position: relative;
        margin-top: 0;
        height: 100%;
    }
}

/* textarea-jumbo */

.input-field {
    &:hover textarea.bordered-textarea.textarea-jumbo {
        box-shadow: 0 0 0 1px $gray-mid;
        border-color: $gray-mid;
    }

    textarea.bordered-textarea.textarea-jumbo {
        border: 1px solid $gray-light;
        border-radius: 3px;
        height: 100%;
        resize: none;
        padding: 1.5em 1.2rem 1em 0.75rem;
        letter-spacing: 0;
        outline: none;
        display: block;

        & + label {
            left: 0.75rem;
            width: calc(100% - 1.9rem);
            background: $white;
            color: $gray-mid;
            top: 1px;
            padding-top: 0.3em;
            transform: none;

            &.active {
                padding: 0;
                transform: none;
            }
        }

        &:focus,
        .focus {
            border-color: $primary !important;
            box-shadow: 0 0 0 1px $primary !important;

            & + label {
                color: $primary;
            }
        }
    }

    .bg-dark & {
        &:hover textarea.bordered-textarea.textarea-jumbo {
            box-shadow: 0 0 0 1px $white;
            border-color: $white;
        }

        textarea.bordered-textarea.textarea-jumbo {
            &:focus,
            .focus {
                border-color: $white !important;
                box-shadow: 0 0 0 1px $white !important;
            }
        }
    }
}

.bg-dark .input-field textarea.bordered-textarea + label {
    .pink & {
        background: $pink;
    }

    .purple & {
        background: $purple;
    }
}

/*modal*/
.modal.open {
    z-index: 1003;
    display: block;
    opacity: 1;
    transform: scaleX(1);
    top: 0px;
}

.modal.modal-share {
    position: relative;
    max-height: 100%;
    overflow-y: visible;
    top: auto !important;
    transform: none !important;

    .modal-footer {
        border-radius: 0 0 4px 4px;
    }

    .preview-item {
        min-height: 300px;
        text-align: center;
        border-radius: 0 0 4px 4px;

        img {
            display: block;
            max-width: 100%;
            height: auto;
        }
    }

    .modal-content {
        h2 {
            font-size: $fz-normal;
            font-weight: 700;
            margin: 0 0 1em;
            letter-spacing: 0.04em;
            line-height: 2rem;
        }

        h3 {
            font-size: $fz-normal;
            line-height: 1.5rem;
        }
    }
}

.video-inner .modal-overlay {
    position: absolute;
    z-index: 1002;
    display: block;
    opacity: 1;
    background: rgba(0, 0, 0, 0.8);
    top: 0;
    height: 100%;
    overflow-y: auto;
}

.share-data {
    font-weight: $fw-normal;
    font-size: $fz-normal;
}

/*help slider*/

avz-help-steps {
    height: 100%;
}

.help-slider {
    height: 100%;
    position: relative;

    .hslider {
        height: 100%;

        .hslider-item {
            display: none;
            padding: 0 1rem;
            margin: 0 0.5rem;
            width: 500px;

            &.active {
                display: flex;
                flex: 1 0 auto;
            }

            h3 {
                font-size: $fz-normal;
                line-height: 1.5rem;
                max-height: 48px;
                margin-bottom: 0.25rem;
                white-space: normal;
                max-height: 64px;
                overflow: hidden;
            }

            .hslide-step {
                font-size: $fz-small;
                line-height: 1.125rem;
                display: inline-block;
                vertical-align: top;
            }

            .hslide-top {
                height: 85px;
                overflow: hidden;
                @extend .flex;
                @extend .flex-row;
                @extend .justify-center;
                align-items: center;

                img {
                    max-height: 100%;
                    height: auto;
                    width: auto;
                    display: block;
                    margin: 0 auto;

                    &.cut {
                        max-height: 80%;
                    }
                }

                &.items-start {
                    align-items: flex-start !important;
                }
            }

            .hslide-bottom {
                @extend .flex;
                @extend .flex-column;
            }

            &.long-descr {
                .hslide-top {
                    height: 65px;
                }
            }
        }
    }

    .chip-jumbo-flat.help-chip {
        position: absolute;
        padding: 0 0.63rem;
        top: 24px;
        left: -24px;
    }
}

.rotate180 {
    transform: rotate(180deg);
}

.slide-icon {
    font-size: 2rem;
}

/*overflow text smozing*/
.help-slider:before {
    content: '';
    position: absolute;
    top: 90px;
    left: -24px;
    width: 24px;
    height: 30px;
    background: transparent;

    .pink & {
        background: -moz-linear-gradient(
            left,
            rgba(233, 30, 99, 0) 0%,
            rgba(233, 30, 99, 1) 100%
        );
        background: -webkit-linear-gradient(
            left,
            rgba(233, 30, 99, 0) 0%,
            rgba(233, 30, 99, 1) 100%
        );
        background: linear-gradient(
            to right,
            rgba(233, 30, 99, 0) 0%,
            rgba(233, 30, 99, 1) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00e91e63', endColorstr='#e91e63', GradientType=1);
    }

    .light-blue & {
        background: -moz-linear-gradient(
            left,
            rgba(3, 169, 244, 0) 0%,
            rgba(3, 169, 244, 1) 100%
        );
        background: -webkit-linear-gradient(
            left,
            rgba(3, 169, 244, 0) 0%,
            rgba(3, 169, 244, 1) 100%
        );
        background: linear-gradient(
            to right,
            rgba(3, 169, 244, 0) 0%,
            rgba(3, 169, 244, 1) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0003a9f4', endColorstr='#03a9f4', GradientType=1);
    }

    .blue-grey & {
        background: -moz-linear-gradient(
            left,
            rgba(96, 125, 139, 0) 0%,
            rgba(96, 125, 139, 1) 100%
        );
        background: -webkit-linear-gradient(
            left,
            rgba(96, 125, 139, 0) 0%,
            rgba(96, 125, 139, 1) 100%
        );
        background: linear-gradient(
            to right,
            rgba(96, 125, 139, 0) 0%,
            rgba(96, 125, 139, 1) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00607d8b', endColorstr='#607d8b', GradientType=1);
    }
}

.help-slider {
    .chip-jumbo-flat.help-chip {
        &.light-blue {
            background-color: shade($light-blue, 12%) !important;
        }

        &.blue-grey {
            background-color: shade($blue-grey, 12%) !important;
        }

        &.pink {
            background-color: shade($pink, 12%) !important;
        }

        &.orange {
            background-color: shade($orange, 12%) !important;
        }
    }
}

.menu-main .menu-panel {
    &.help-in {
        padding-right: 20px;
        transition: height 0.5s linear 0s, width 0.5s linear 0s,
            padding-right 0.3s linear 0.5s;
        will-change: height, width, padding-right;

        .panel-top > *:last-child {
            margin-right: 0.625rem;
        }

        .help-slider {
            padding: 0;
            margin: 0;
            border-radius: 0 8px 8px 0;
            max-width: 0px;

            .chip-jumbo-flat.help-chip {
                opacity: 0;
                -webkit-transition: opacity 0.3s linear 0s;
                transition: opacity 0.3s linear 0s;
            }

            .panel-top {
                padding-top: 1.5rem;
                padding-bottom: 1.25rem;
            }

            &.active {
                padding-left: 34px;
                padding-right: 10px;
                max-width: 2000px;
            }
        }

        .cam-control-buttons {
            .panel-top {
                padding-top: 1.5rem;
                padding-bottom: 1.25rem;
            }
        }

        .action-section {
            transition: none;

            .panel-top {
                position: relative;
                -webkit-transition: margin 0.24s linear 0s,
                    padding-right 0.9s linear 0s;
                transition: margin 0.24s linear 0s, padding-right 0.9s linear 0s;
                padding-right: 122px;
                will-change: margin, padding-right;
            }

            .chip-jumbo-flat.help-chip {
                opacity: 1;
                -webkit-transition: opacity 0.3s linear 0s;
                transition: opacity 0.3s linear 0s;
                position: absolute;
                will-change: opacity;
            }
        }

        .text-pull {
            width: calc(100% - 122px);
        }

        &.help-open {
            transition: height 0.5s linear 0s, width 0.5s linear 0s,
                padding-right 0s linear 0s;
            will-change: height, width, padding-right;
            padding-right: 0px;

            &.device-panel {
                padding-right: 0px;
                padding-left: 0;
            }

            .help-slider {
                .chip-jumbo-flat.help-chip {
                    opacity: 1;
                    -webkit-transition: opacity 0.3s linear 0s;
                    transition: opacity 0.3s linear 0s;
                    will-change: opacity;
                }
            }

            .action-section {
                .panel-top {
                    padding-right: 0;
                    margin-right: 24px;
                    -webkit-transition: margin 0s linear 0s,
                        padding-right 0s linear 0.3s;
                    transition: margin 0s linear 0s,
                        padding-right 0s linear 0.3s;
                    will-change: margin, padding-right;
                }

                .chip-jumbo-flat.help-chip {
                    opacity: 0;
                    -webkit-transition: opacity 0.3s linear 0s;
                    transition: opacity 0.3s linear 0s;
                    will-change: opacity;
                }
            }
        }
    }
}

.help-slider.animated {
    &.fadeIn {
        animation-duration: 1s;
        animation-delay: 0.3s;
        -webkit-transition: all 1s cubic-bezier(0.35, 0.12, 0.37, 0.97) 0s;
        transition: all 1s cubic-bezier(0.35, 0.12, 0.37, 0.97) 0s;
    }

    &.fadeOut {
        pointer-events: none;
        cursor: default;
        animation-duration: 0.5s;
        animation-delay: 0s;
        -webkit-transition: all 1s ease 0s;
        transition: all 1s ease 0s;
    }
}

.video-item.preview-item {
    background-color: #000 !important;

    & > * {
        max-width: 100%;
        max-height: 100% !important;
        height: inherit;
        width: 100%;
    }
}

/* participants */

.people-count {
    h1 {
        margin-top: 30px;
        margin-bottom: 0;
        font-size: $fz-h2;
    }

    h3 {
        font-size: $fz-normal;
    }
}

/* active participants*/
.img-panel {
    width: 140px;
    height: 140px;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    &:before {
        display: block;
        height: 100%;
        width: 100%;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background: -moz-linear-gradient(
            top,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0) 40%,
            rgba(0, 0, 0, 0) 55%,
            rgba(0, 0, 0, 0.15) 70%,
            rgba(0, 0, 0, 0.5) 100%
        );
        background: -webkit-linear-gradient(
            top,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0) 40%,
            rgba(0, 0, 0, 0) 55%,
            rgba(0, 0, 0, 0.15) 70%,
            rgba(0, 0, 0, 0.5) 100%
        );
        background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0) 40%,
            rgba(0, 0, 0, 0) 55%,
            rgba(0, 0, 0, 0.15) 70%,
            rgba(0, 0, 0, 0.5) 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=0);
        z-index: 1;
    }

    img {
        max-height: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 0;
    }

    &.img-panel__auto {
        width: auto;

        img {
            height: 100%;
            width: auto;
            position: relative;
            transform: none;
            left: 0;
            top: 0;
        }
    }

    .p-name {
        position: absolute;
        bottom: 10px;
        font-size: $fz-normal;
        left: 0;
        width: 100%;
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
        z-index: 1;
    }

    .p-icon {
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 0 8px 0 8px;
        background: rgba($black, 0.12);
        width: 48px;
        height: 48px;
        z-index: 1;

        i {
            font-size: 1.5rem;
            margin: auto;
        }

        .preloader-wrapper {
            margin: auto;

            &.small {
                width: 24px;
                height: 24px;
            }
        }
    }
}

.alt-text,
.alt-icon {
    border: 2px solid #fff;
    border-radius: 50%;
    margin: 20px auto 0;
    width: 70px;
    height: 70px;
    line-height: 70px;
    font-size: 24px;

    .material-icons,
    .icon {
        font-size: 2rem;
        line-height: 66px;
    }
}

.spinner-layer.spinner-white-only {
    border-color: $white;
}

.img-square {
    width: 156px;
    height: 156px;
    margin-right: 30px;
    position: relative;
    overflow: hidden;
    border-radius: 8px 0 0 8px;
    transition: all 0.5s linear 0s;

    img {
        max-height: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .alt-text,
    .alt-icon {
        margin: auto;
    }
}

.alt-icon {
    border: none;

    &.material-icons,
    &.icon {
        font-size: 2rem;
        line-height: 70px;
    }
}

.alt-text.material-icons {
    font-size: 2rem;
    line-height: 66px;
}

/* attachments */

table.table-vert {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    display: block;
    position: relative;
    border: none;
    font-size: 1rem;
    line-height: 1.5rem;

    td,
    th {
        padding: 0;
        border-radius: 2px;
    }

    thead {
        display: block;
        float: left;
        border: 0;
        border-right: none;

        tr {
            display: block;
            padding: 0 20px 0 0;
        }
    }

    th {
        display: block;
        text-align: left;
        border-bottom: none;
    }

    tbody {
        display: block;
        width: auto;
        position: relative;
        overflow-x: auto;
        white-space: nowrap;

        tr {
            display: inline-block;
            vertical-align: top;
            padding: 0;

            &:hover {
                background: none;
            }
        }
    }

    td {
        display: block;
        text-align: left;
        margin: 0;
        vertical-align: top;
    }
}

.video-inner .overlay {
    height: 100%;
    width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.7);

    .preview-item {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 100%;

        .resize-btn {
            position: absolute;
            top: 40px;
            right: 40px;
        }
    }

    &.overlay__fullscreen {
        height: 100vh;
        z-index: 10;
    }
}

.full-on,
.full-off {
    &.animated {
        animation-duration: 0.16s;
    }
}

/*text file*/
.preview-text {
    overflow-y: auto;
}

/*audio player*/
.player-container {
    // display: block;
    // margin: auto;
    // width: 380px;
    // height: 62px;
    // background: $white;
    // border-radius: 5px;
    // padding: 18px 15px 0;
    .player {
        .player-inner {
            width: calc(100% - 80px);
            min-width: 90px;
            color: $gray-mid;
            font-size: 12px;
            margin-right: 40px;

            .player-bar {
                background: $primary-light;
                height: 3px;
                position: relative;
                border-radius: 1px;
                margin-bottom: 10px;
                cursor: pointer;

                .progress-line {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 0;
                    background: $primary;
                    border-radius: 0;
                }

                span {
                    position: absolute;
                    height: 9px;
                    width: 9px;
                    border-radius: 50%;
                    background: $primary;
                    top: -3px;
                    left: 0;
                    margin-left: -4px;
                }
            }
        }

        .material-icons {
            font-size: 36px;
            line-height: 1;
            margin-top: -4px;
            cursor: pointer;
        }
    }
}

.video-box {
    background: $gray-mid;
    position: relative;
    display: inline-block;

    &.video-overlay {
        &:before {
            content: '';
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            transition: all 0.3s linear 0s;
            will-change: all;
            z-index: 1;
        }
    }

    .btn-play,
    .btn-pause {
        background: transparent;
        border: 0;
        width: 60px;
        height: 60px;
        padding: 0;
        text-align: center;
        position: absolute;
        top: calc(50% - 30px);
        left: calc(50% - 30px);
        cursor: pointer;
        z-index: 2;

        .material-icons {
            color: $white !important;
            margin: 0 !important;
            display: inline-block;
            font-size: 60px;
            line-height: 60px;
            vertical-align: 0 !important;
            transition: all 0.5s ease-in-out;
        }

        &:hover .material-icons {
            color: #757575 !important;
        }
    }

    .media-loading {
        display: inline-block;
        font-size: 12px;
        color: #fff;
        font-weight: 400;
        position: absolute;
        bottom: 5px;
        left: calc(50% - 25px);
        right: auto;
    }

    .video-controls {
        position: absolute;
        bottom: 13px;
        width: 100%;
        z-index: 2;

        .video-time {
            width: 50px;
            text-align: center;
            display: inline-block;
            font-size: 12px;
            color: $white;
            font-weight: 400;
        }

        .video-progress-wrapper {
            height: 24px;
            position: relative;
            z-index: 1;
            width: calc(100% - 100px);
            display: inline-block;
            vertical-align: -3px;
            cursor: pointer;

            .video-progress {
                display: inline-block;
                height: 3px;
                background-color: #fff;
                -moz-border-radius: 5px;
                -webkit-border-radius: 5px;
                -ms-border-radius: 5px;
                border-radius: 5px;
                overflow: hidden;
                width: 100%;
                vertical-align: 7px;

                .progress-bar {
                    height: 3px;
                    top: 8px;
                    background: $primary;
                    position: absolute;
                    display: inline-block;
                    left: 0;
                    width: 25.5607%;
                    -moz-border-radius: 5px 0 0 5px;
                    -webkit-border-radius: 5px 0 0 5px;
                    -ms-border-radius: 5px 0 0 5px;
                    border-radius: 5px 0 0 5px;
                }

                .progress-slider {
                    top: 5px;
                    width: 9px;
                    height: 9px;
                    background: $primary;
                    -moz-border-radius: 50%;
                    -webkit-border-radius: 50%;
                    -ms-border-radius: 50%;
                    border-radius: 50%;
                    cursor: pointer;
                    position: absolute;
                    display: inline-block;
                    cursor: default;
                    z-index: 5;
                    left: 25%;
                    margin-left: -5px;
                }
            }
        }
    }
}

.stroke-info {
    h1 {
        font-size: 1.5rem;
        line-height: 2rem;
        margin-bottom: 0.5rem;
    }

    h3 {
        p {
            margin-bottom: 0;
            font-size: 16px;
            line-height: 1.5rem;
        }
    }
}

.attach-removed {
    cursor: default;
    pointer-events: none;

    .removed-scrim {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        background: rgba($white, 0.78);
        z-index: 1;
        opacity: 1;
        display: block;

        .removed-scrim_inner {
            color: $gray-mid;
            font-size: 1rem;
            cursor: default;
            pointer-events: none;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;

            .material-icons {
                font-size: 24px;
                vertical-align: 0;
                margin-right: 5px;
            }
        }
    }
}

.video-box {
    .btn-pause {
        opacity: 0;
        transition: opacity 0.3s linear 0s;
        will-change: opacity;
    }
}

.video-box:hover {
    .btn-pause {
        opacity: 1;
        transition: opacity 0.3s linear 0s;
        will-change: opacity;
    }
}

.modal.share-dev-modal {
    .modal-footer {
        text-align: right;

        .btn {
            vertical-align: top;
            float: none;

            &:first-child {
                margin-bottom: 0.5rem;
            }
        }
    }
}

/*share*/
.video-item.preview-item {
    width: auto;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .player-container {
        display: block;
        max-width: 100%;
        max-height: 100%;

        @media (max-width: 960px) {
            width: 100%;
        }
    }

    avz-text-panel {
        max-height: 100%;
        max-width: 100%;
        display: block;
        overflow-y: auto;
        width: auto;
        height: 100%;

        .container {
            width: 100%;
            padding: 0 3.5rem;
        }
    }

    avz-video-player {
        width: auto;
        height: 100%;
        max-width: 100%;
        max-height: 100%;

        .video-box {
            display: block;
            width: auto;
            height: 100%;
            max-width: 100%;
            max-height: 100%;
            margin: auto;

            .video {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
}

/*local view*/
avz-attach-preview-frame avz-attachment-preview {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    max-height: 100%;
    padding: 40px;

    img {
        max-height: 100%;
        max-width: 100%;
        display: block;
    }

    avz-text-panel {
        max-height: 100%;
        max-width: 100%;
        display: block;
        overflow-y: auto;
    }

    #attachment-preview-block {
        .video-item {
            avz-video-player {
                width: 100%;
                height: 100%;
                max-width: 100%;
                max-height: 100%;
                display: flex;
                justify-content: center;

                .video-box {
                    width: 90%;
                    height: 100%;
                    max-width: 100%;
                    max-height: 100%;

                    .video {
                        width: 100%;
                        height: 100%;
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }
        }
    }
}

.error-content {
    .icon {
        font-size: 160px;
        width: 160px;
        height: 160px;
        display: inline-block;
        color: $primary;
    }
}

.error-modal {
    .icon {
        font-size: 80px;
        height: 80px;
        width: 80px;
        display: inline-block;
        color: $primary;
    }
}

.progress-button.btn-large {
    padding-bottom: 2px;
}

.progress-button.btn-large.disabled.btn-primary {
    background: $primary !important;
    color: $white !important;

    .content:after,
    .content:before {
        margin-left: -18px;
    }

    &.progress-disable {
        background: rgba($black, 0.12) !important;
        color: rgba(0, 0, 0, 0.26) !important;
    }
}

.fullheight {
    height: calc(100vh - 70px);
}

.video-topnav.slideoff {
    top: 0;
}

#imageshare {
    width: auto;
    height: 100%;
    margin: 0 auto;
    display: block;
}

#presentationvideo {
    width: 952px;
    height: 540px;
    display: block;
    margin: 0 auto;
    max-width: 100%;
    max-height: 100%;
}

.avz-dialog.avz-dialog-theme-avizia
    .avz-dialog-content
    .modal-content.network-lost-modal {
    padding-bottom: 4.375rem;

    & + .avz-dialog-close {
        display: none;
    }

    h3 {
        font-size: 2.25rem;
    }

    .icon.layered {
        width: 160px;
        height: 160px;
        font-size: 160px;
        display: block;
        margin: 0 auto 1rem;
    }
}

.lost-connection-screen {
    padding-bottom: 148px;
    padding-top: 3rem;

    background: transparent;
    -ms-overflow-style: none;
    scrollbar-width: none;

    .no-network-connectivity.row {
        margin-left: 2.25rem;
        margin-right: 2.25rem;
    }

    .white-box {
        background: $white;
        border-radius: 4px;
        box-shadow: 0 0 4px rgba($black, 0.16);
        padding: 0 1.5rem !important;

        .btn-large {
            box-sizing: border-box;

            &.btn-fullwidth {
                width: 100%;
            }
        }

        .col {
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;

            &.border-right {
                border-right: 1px solid $gray-light;
            }
        }

        .bordered {
            border: 1px solid $gray-light;

            .btn-wrap {
                margin: 1rem;
            }

            .collection {
                height: 395px;
                overflow: auto;

                a.collection-item.active {
                    .icon {
                        color: $primary;

                        &:before {
                            color: $primary;
                        }

                        .path1,
                        .path2,
                        .path3 {
                            &:before {
                                color: $primary;
                            }
                        }
                    }
                }
            }
        }

        .input-frame {
            height: 77px;
        }
    }

    .footer-stick {
        background-color: #25abfd !important;
        background: linear-gradient(15deg, #25abfd 0%, #0469bd 100%) !important;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        border-top: 1px solid rgba($white, 0.2);
        padding: 2.25rem;
        z-index: 2;

        .btn.disabled,
        .btn-large[disabled],
        .btn:disabled,
        .btn[disabled] {
            border-color: transparent;
            background: rgba($white, 0.12) !important;
            color: rgba($white, 0.3) !important;
        }
    }

    .cc-button {
        &.align-left {
            text-align: left;
        }

        &.selected {
            border-color: $primary;
            background: rgba($primary, 0.05);
        }
    }
}

.gray-light.a30 {
    background: rgba($gray-light, 0.3) !important;
}

.word-break {
    word-break: break-all;
}

.form .input-top-space-0 .input-field {
    margin-top: 0;
}

.h1-icon {
    font-size: 4rem !important;
}

.intmHomeScreenSaver {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: -1;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.intm-home-bottom-left {
    margin-bottom: 65px;
    margin-left: 75px;
    height: 125px;
    color: white;
    position: fixed;
    bottom: 0;
}

.intm-home-bottom-left-logo {
    margin-left: 1%;
    float: right;
    height: 25px;
}

.intm-home-bottom-left-logo-section {
    padding-top: 12px;
    font-size: 15px;
    width: 196px;
    font-weight: bold;
    position: absolute;
    bottom: 0;
    margin-bottom: -10px;
}

.intm-home-bottom-right {
    bottom: 0;
    right: 0;
    position: fixed;
    margin-bottom: 75px;
    margin-right: 75px;
    z-index: 1;
    width: auto;
    height: 80px;
}

.intm-home-bottom-right-name {
    display: inline-block;
    padding-left: 24px;
    padding-right: 24px;
    height: 80px;
    line-height: 80px;
}

.intm-home-bottom-right-logo {
    display: inline-block;
    vertical-align: top;
    padding: 16px;
    background-color: white;
    height: 80px;
}

.video-two-screen {
    display: flex;
    flex-wrap: wrap;
    position: absolute;

    > .video-item {
        max-width: 50%;
        max-height: 100%;
        width: auto;
        height: 100%;
        flex: 50%;
        min-width: 50%;

        &#remotevideo {
            @media (max-width: 1366px) {
                height: 375px;
            }
        }

        > div {
            display: flex;
            align-items: center;
        }
    }
}

.shared-device-holder {
    .top-box {
        position: relative;
        z-index: 3;
    }

    .checkbox label {
        color: #fff !important;

        @media #{$xxlarge-and-up} {
            font-size: 1.5rem;
        }
    }

    .radio [type='radio'] + label {
        color: #fff !important;
    }

    .radio .hover {
        left: -8px !important;
        top: -7px;
    }

    [type='radio']:checked + label,
    [type='radio']:not(:checked) + label {
        font-size: 16px;

        @media #{$xxlarge-and-up} {
            font-size: 1.5rem;
        }
    }

    [type='radio'] + label:before,
    [type='radio'] + label:after {
        border-color: #fff;
    }

    .radio [type='radio']:not(:checked) + label:before,
    .radio [type='radio']:not(:checked) + label:after {
        border-color: #fff;
    }

    input:checked:focus ~ .hover,
    input:not(:checked):focus ~ .hover {
        background: rgba(70, 175, 242, 0);
    }

    .helper {
        border-color: #fff;
        width: 24px;
        height: 24px;
        top: 0;
    }

    .aw-slider {
        i {
            color: #fff !important;
        }
    }

    a.link {
        color: #46aff2;
    }
}

.dash-box .cc-button {
    span {
        margin: 0 auto 0.5rem !important;
    }
}

.shared-device-holder .a.link {
    color: #46aff2;
}

.pink {
    .help-slider {
        &:before {
            background: transparent;
        }
    }
}

.slider-volume {
    .aw-slider {
        i {
            color: #ffffff !important;
        }
    }
}

.radio [type='radio']:not(:checked) + label,
.radio [type='radio']:checked + label {
    width: 100%;
}

.slider-volume-custom {
    nouislider {
        margin-right: 23px !important;
    }
}

.wizard-equalizer {
    min-width: 290px !important;
}

.switch label .lever {
    &:before {
        top: 6px;

        @media #{$xxlarge-and-up} {
            top: -3px;
        }
    }
}

.scrollable-wifi-settings {
    .aw-collapsible {
        margin-left: 1px !important;
    }
}

.before-connect-alt {
    min-height: 465px;
}

button,
.btn-floating {
    outline: none !important;
    cursor: default !important;

    &:focus-visible {
        outline: none !important;
    }
}

.btn-menu {
    width: 72px !important;
    height: 72px !important;

    i.material-icons {
        line-height: 72px !important;
    }
}

.aw-collapsible {
    li {
        outline: none;
    }
}

.btn-round {
    border-radius: 100px;
}

.coal {
    background: #232a30 !important;
}

.disable-click {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0);
    z-index: 1005;
    cursor: pointer;
}

.dash-box {
    .input-field-new.has-label .input-field-float-label {
        top: 0;
    }
}

.aw-collapsible {
    .aw-dropdown-content {
        max-height: 350px !important;
    }
}

.justify-evenly {
    justify-content: space-evenly;
}

.justify-space-around {
    justify-content: center;
    gap: 2rem;
}

.centered-align {
    align-items: center;
}

app-battery-level {
    max-height: 24px;
}

.cam-actions a:hover {
    cursor: pointer;
}

.margin-btn-sm {
    margin-bottom: 0.7rem;
}
