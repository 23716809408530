.select-option-header {
    label {
        color: #bec3cc !important;
    }
}

.header-logo {
    height: 40px;
}

.header-content-light {
    min-height: 98px;
}

.page-content-light {
    min-height: calc(100% - 98px);
}

.page-content-main {
    min-height: calc(100% - 40px);
}

.page-content-main-footer {
    min-height: calc(100% - 162px);
}

.footer-content-main {
    min-height: 98px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
}

.page-content-main-footer,
.page-content-main {
    .title-block {
        top: 0;
        height: 200px;
    }
}

.panel {
    border: none;
    background: $white;
    //max-width: 1200px;

    &.panel-transparent {
        background: transparent;
        border: 1px solid $gray-light;
        max-height: 308px;
        max-width: 353px;
    }

    &.panel-notyfication {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .panel-heading,
    .panel-body {
        padding: 2rem !important;
    }

    .panel-footer {
        background: none;
        padding: 2rem !important;
    }
}

.waiting-page-title {
    margin-bottom: 9rem;
}

.waiting-page-img {
    position: absolute !important;
    top: -110px;
    margin: auto;
    left: 0;
    right: 0;
}

.reassign-page-img-right {
    position: absolute;
    right: 50%;
    top: -100px;
    margin-right: -300px !important;
}

.img-reassign-block {
    height: 0;
}

.reassign-page-img-left {
    position: absolute;
    left: 50%;
    top: -100px;
    margin-left: -300px !important;
}

.waiting-panel {
    background: $white;
    border: 1px solid $gray-light;
    border-radius: 4px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.12);
    position: relative;
    text-align: center;

    .waiting-panel-body {
        padding: 9rem 2rem 0 2rem;
    }

    .waiting-panel-footer {
        padding: 1rem 2rem 1rem 2rem;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    .fz160 {
        width: 10rem !important;
        height: 10rem !important;
        font-size: 120px !important;
        text-align: center;
    }
}

.material-icons {
    &.large-material {
        font-size: 36px;
        line-height: 36px;
    }

    .title-block {
        top: 0;
        height: 150px;
    }
}

.btn-flat {
    &.btn-simple {
        &.btn-simple-white {
            color: $white !important;

            &:hover {
                color: $white !important;
                background: rgba($black, 0.1);
            }
        }
    }
}

.menu-call {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;

    i {
        &:active,
        &:hover,
        &:focus,
        &:focus-visible {
            color: var(--primary-2);
        }
    }

    .icon-link {
        height: auto;
    }
}

.dash-box {
    background: $white;
    border: 1px solid $gray-light;
    border-radius: 4px;
    box-shadow: 0 0 4px rgba($black, 0.12);
    padding: 1rem 2rem;

    .material-icons {
        margin: 0;
    }

    .cc-button {
        height: 100%;
    }
}

// custom styles, need remove
.aw-modal.settings-pin,
.settings-pin .mat-dialog-container {
    width: 97% !important;
    max-height: 95% !important;
    height: 95% !important;
    align-items: center;
    justify-content: center;
}

#pinInput {
    width: 0;
    padding: 0;
    border: none;
}

.aw-list {
    li.collection-item {
        .list-item-inner {
            .power-menu {
                color: $gray-light;
            }
        }

        &:hover {
            .list-item-inner {
                .power-menu {
                    color: $gray-mid;
                }
            }
        }
    }
}

.modal-footer {
    .btn.btn-danger {
        &:focus {
            background: $danger !important;
        }
    }
}

.caller-white-block {
    .call-buttons {
        width: 490px;
    }

    button.btn-floating {
        line-height: 32px;
        width: 100%;
        height: 48px;
        min-width: 88px !important;
        border-radius: 100px;
        display: flex;
        align-items: center;
        padding: 0 24px !important;

        &:after {
            border-radius: 100px !important;
        }
    }

    .btn-floating {
        width: 100%;
        height: 48px;
        line-height: 48px;
        min-width: 88px !important;
        border-radius: 100px;
        display: flex;
        align-items: center;
        padding: 0 24px !important;

        .white-text {
            margin: 0;
            width: 100%;
            white-space: nowrap;
        }

        i {
            font-size: 2rem !important;
            transition: line-height 0.3s, font-size 0.3s;
            min-width: 30px;
            height: 30px;
            line-height: 30px !important;
            margin-right: 10px !important;

            &.material-icons {
                margin: 0;
            }

            &.add {
                font-size: 1.9rem !important;
            }
        }

        &:after {
            border-radius: 100px !important;
        }
    }
}

.slider-jumbo .noUi-horizontal .noUi-handle {
    top: -7px !important;
}

.btn-jumbo button {
    width: 220px;
    height: 220px;
    font-size: 1.5rem;
    border: none;
    display: inline-block;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
    padding: 0;
    border-radius: 8px;
    text-transform: none;
    letter-spacing: 0;
    outline: 0;
    transition: all 0.5s linear 0s, background 0.3s linear 0s;
    background-color: #000 !important;
    max-height: 95% !important;
}

.custom-providers {
    position: relative;
    z-index: 1;
    background-color: #f2f2f2 !important;
}

button.custom-btn {
    .material-icons {
        font-size: 24px;
        width: 54px;
        height: 54px;
        line-height: 54px;
    }
}

.rating-holder {
    text-align: center;
    padding-top: 1rem;

    .rating-box {
        display: inline-block;
    }
}

.aw-rating {
    [type='radio']:checked + label,
    [type='radio']:not(:checked) + label {
        width: 1.5em;
    }
}

.aw-modal {
    .modal-heading h4 {
        font-size: $fz-h3;

        .material-icons {
            margin-right: 1.5rem;
            font-size: 2rem;
            vertical-align: -6px;
        }

        .modal-content.network-lost-modal {
            padding-bottom: 4.375rem;
        }
    }

    &.leave-case-open {
        .modal-footer {
            flex-direction: row-reverse;
        }
    }
}

.logo-box {
    margin: 0 auto;
    width: 100px;
    padding-top: 30px;
}

.new-calendar-forms .mat-form-field {
    width: 100%;
}

.new-calendar-forms .mat-form-field .mat-input-element {
    padding: 0;
    margin: 0;
    border: none;
}

.modal-content-wrapper {
    .aw-dropdown-content {
        max-height: 300px !important;
    }
}

.radio-buttons-list {
    .radio {
        label {
            white-space: nowrap;
        }
    }
}

.modal-content-alt {
    display: flex;
    flex: 1 0 auto !important;
    height: 100%;
}

.overflow-y {
    overflow-y: auto;
}

.wakeup {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.lock-screen {
    .step-two {
        margin-top: 100px;
    }
}

.ng-animating {
    #videoConsoleNode {
        .awl-self-container {
            display: none !important;
        }
    }
}

#videoConsoleNode {
    .awl-modal {
        outline: none !important;
    }
}

.btn-floating::before {
    border: none !important;
}

#intakeForm {
    z-index: 3;
}

.aw-collapsible-header {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    span > .material-icons-outlined {
        height: 1rem !important;
    }
}

.error-red {
    color: darkred !important;
}

.overflow-text {
    overflow-wrap: break-word;
    white-space: normal;
    width: 800px;
}

.camera-error-padding {
    padding-bottom: 1.25rem !important;
}

/* Custom fixes to support current UI theme */
.btn.btn-simple {
    color: var(--btn-simple-color);
    background: var(--simple-background);
    line-height: var(--btn-simple-line-height);
    border: var(--simple-border);
    box-shadow: var(--btn-simple-box-shadow);
}

.btn.btn-simple:hover {
    color: var(--btn-simple-color);
    background: var(--btn-simple-background-hover);
}

.btn.btn-simple:active,
.btn.btn-simple:focus-visible {
    color: var(--btn-simple-color);
    background: var(--btn-simple-background-active);
}

.btn.btn-simple:disabled,
.btn.btn-simple[disabled],
.btn.btn-simple.disabled {
    color: var(--btn-btn-simple-color-disabled);
    box-shadow: none;
    background: var(--btn-simple-disabled-background);
    border: 1px solid var(--btn-simple-border-disabled);
}

.btn.btn-simple.btn-small {
    line-height: var(--btn-small-line-height);
}

.btn.btn-simple.btn-large {
    line-height: var(--simple-btn-large);
    min-height: var(--simple-btn-large);
}

.btn.btn-simple.progress-button.progress-v2 .content::before,
.btn.btn-simple.progress-button.progress-v2 .content::after {
    color: var(--btn-simple-color) !important;
}

.btn.btn-simple.progress-button.progress-v2 aw-spinner .spinner-layer {
    border-color: var(--btn-simple-color) !important;
}
