.page-heading-simple {
    margin: 0;
    padding: 1.5rem 2rem 1.5rem 5rem;

    @media (max-width: 767px) {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.settings-logo {
    width: 160px;
}

h4.section-title {
    .material-icons {
        vertical-align: -6px;
        margin-left: 0.5rem;
    }
}

.settings-tabs {
    .aw-indicator {
        background: $white !important;
    }

    .white-text {
        a {
            color: #fff !important;
        }
    }

    .tab {
        a {
            padding: 0 45px !important;
        }

        &:first-child {
            a {
                padding-left: 80px !important;
            }

            @media (max-width: 960px) {
                margin: 0 !important;
            }

            @media (max-width: 767px) {
                padding-left: 40px !important;
            }
        }
    }
}

// Only for Settings tab (old blue gradient design, non-converge)
.aw-modal .mat-dialog-container .modal-heading .settings-header + aw-button {
    position: relative;
    top: 0;
    right: 0;
}

.material-tooltip,
.backdrop {
    display: block;
}

.settings-panel {
    padding: 1rem 0;
    border-top: 1px solid $gray-light;
}

.state {
    .material-icons {
        vertical-align: -6px;
        width: 1.5rem;
        margin-bottom: auto;

        @media (min-width: 1600px) {
            margin-top: 0.4rem;
        }
    }
}

.add-block {
    p {
        padding: 1.5rem 0 0;
        margin-bottom: 0.5rem;
    }
}

.cam-preview {
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 4px;

    .material-icons {
        font-size: 48px;
    }

    img,
    video {
        position: absolute;
        min-width: 100%;
        min-height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .btn-wrapper {
        position: absolute;
        bottom: 0;
        width: 100%;
        text-align: center;
        left: 0;
    }
}

.collection a.collection-item {
    cursor: pointer;
}

.collection a.collection-item .caption.inline {
    display: inline;
    color: inherit;
}

.dot.wifi-ind {
    background: $gray-light;
    border-color: $gray-light;
    vertical-align: 0;

    &.success {
        border-color: $success;
    }
}

table tbody.simple-text tr td {
    height: 55px;
}

table thead th .material-icons.sorting {
    vertical-align: -6px;
    margin-left: 0.5rem;
}

.panel table.btop-none {
    border-top: none;
}

p.label {
    font-size: 0.75rem;
    color: $gray-mid;
}

.noUi-origin {
    background: #9d9d9d;
    bottom: 10px;
}

.material-tooltip {
    margin-top: 13px;
}

.page-content.overflow-auto {
    height: 100%;
    overflow: auto;
}

.collapsible.no-styles {
    box-shadow: none;
    border: none;

    .collapsible-header {
        cursor: default;
        min-height: 0;
        line-height: inherit;
        padding: 0;

        &:after {
            clear: both;
            content: '';
            display: block;
        }

        i {
            width: auto;
            line-height: inherit;
            float: none;
            transform: none;
            display: inline-block;
        }
    }

    .collapsible-body {
        border: none;
    }
}

.modal-form,
.networks-list {
    position: relative;

    .loading {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        font-size: $fz-small;

        avz-spinner-component {
            margin-right: 0.5rem;
        }

        .preloader-wrapper.small {
            width: 1rem;
            height: 1rem;
        }

        .circle-clipper .circle {
            border-width: 2px;
        }
    }

    &.load {
        .modal-form-content {
            opacity: 0.2;
        }

        .loading {
            opacity: 1;
        }
    }
}

.advanced-connectivity {
    .bordered {
        border: 1px solid $gray-light;
        min-height: 100%;
    }

    .row {
        margin-left: -0.75rem !important;
        margin-right: -0.75rem !important;

        .col {
            padding-left: 0.75rem !important;
            padding-right: 0.75rem !important;
        }
    }

    .collection.connectivity {
        a.collection-item.active,
        a.collection-item.active:hover {
            color: $gray-dark;
            background: rgba($gray-light, 0.3);
        }

        a.collection-item.active:hover .caption {
            color: $gray-dark;
        }

        .caption {
            .dot {
                vertical-align: 1;
                margin-right: 0.5rem;

                .success {
                    border-color: $success;
                }

                .warning {
                    border-color: $warning;
                }

                .danger {
                    border-color: $danger;
                }
            }
        }

        a.collection-item .material-icons {
            color: $gray-dark;
        }
    }

    .net-list-wrap {
        max-height: 255px;
        overflow: hidden;
        border: 1px solid $gray-light;

        .collection {
            max-height: 255px;
            overflow: auto;

            a.collection-item {
                .material-icons.right {
                    opacity: 0;
                }

                .icon {
                    color: $gray-dark;
                }
            }

            a.collection-item.active {
                .material-icons.right {
                    opacity: 1;
                }

                .icon {
                    color: $primary;

                    .path1:before,
                    .path2:before,
                    .path3:before {
                        color: $primary;
                    }
                }
            }
        }
    }

    .networks-list {
        padding-top: 3rem;
    }
}

.player-container {
    padding: 0;
}

.collection.settings-scroll {
    max-height: 570px;
    overflow: auto;
}

.progress-box {
    .slider-holder {
        padding-left: 45px;
    }

    .player-buttons {
        float: left;
    }

    .player-buttons {
        span {
            display: inline-block;
            cursor: pointer;
        }

        .material-icons {
            font-size: 36px;
        }
    }
}

.wizard-equalizer {
    width: 100%;
}

.aw-collapsible-body {
    &.aw-content-body {
        & > div {
            padding-bottom: 1.5rem;
        }
    }
}

.aw-modal {
    .mat-dialog-container {
        .modal-content-wrapper {
            max-height: 100%;
        }
    }
}

aw-radio-button {
    .radio {
        display: flex;
        align-items: center;

        &:last-child {
            margin: 1rem;
        }

        label {
            &:before,
            &:after {
                top: 1px;
            }
        }
    }
}

.collection .icon {
    display: inline-block;
    width: 32px;
    height: 32px;
    font-size: 32px;
    margin-top: -16px !important;

    &.layered {
        width: 32px;
        height: 32px;
    }
}

app-settings-modal {
    .aw-collapsible-header > div {
        display: flex;
        align-items: center;
    }

    h3 {
        word-break: break-word;
    }
}

.linl-join {
    color: #3e88ce;
    cursor: pointer;
}

.camera-text-width {
    max-width: 475px;
}
